import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import Status from "../../../Componets/Status";
import ActionButton from "../../../Componets/Buttons/action";
import Table from "../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../Data/Tables/Employees/Employees/tables";
import headers from "../../../Data/Tables/Employees/Employees/headers";
import TableAvatar from "../../../Componets/TableAvatar";
import { get_all_employees } from "../../../Controllers/Employees";

const EmployeesTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
      const customers = await get_all_employees();
      if (customers?.status) {
        let data = [];

        customers?.data?.map((value, index) => {
          data?.push({
            Name: <TableAvatar name={value?.name} />,
            Role: value?.role?.name,
            Email: value?.email,
            Phone: value?.phone,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/employees/edit/${value?._id}`}
                delete="/"
              />
            ),
          });
        });

        setBody(data);
      } else {
        toast?.error(customers?.message);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default EmployeesTable;
