import { createBrowserRouter } from "react-router-dom";
import Layout from "../Componets/Layout";
import Dashboard from "../Pages/Dashboard";
import Project from "../Pages/Projects";
import Signin from "../Pages/Authentication/signin";
import Purchases from "../Pages/Purchases";
import Reports from "../Pages/Reports";
import Settings from "../Pages/Settings";
import Sales from "../Pages/Sales";
import AddProject from "../Pages/Projects/AddProject";
import AddArea from "../Pages/Projects/Area/AddArea";
import ViewProject from "../Pages/Projects/ViewProject";
import ViewArea from "../Pages/Projects/Area/ViewArea";
import AddTasks from "../Pages/Projects/Tasks/AddTasks";
import ViewTask from "../Pages/Projects/Tasks/ViewTask";
import AddTimesheet from "../Pages/Projects/Timesheet/AddTimesheet";
import ViewTimesheet from "../Pages/Projects/Timesheet/ViewTimesheet";
import AddPurchase from "../Pages/Purchases/AddPurchase";
import Quotes from "../Pages/Quotes";
import AddSales from "../Pages/Sales/ViewSales";
import InvoicePage from "../Pages/Sales/ViewSales/page";
import AddQuotes from "../Pages/Quotes/AddQuotes";
import QuotationPage from "../Pages/Quotes/AddQuotes/page";
import PurchasePage from "../Pages/Purchases/AddPurchase/page";
import ViewReports from "../Pages/Reports/ViewReports";
import AddIssue from "../Pages/Projects/Tasks/AddIssue";
import ItemForm from "../Pages/Items/Form";
import ItemsTable from "../Pages/Items/All";
import AddForm from "../Pages/AddForm";
import SuppliersForm from "../Pages/Suppliers/Form";
import SuppliersTable from "../Pages/Suppliers/Table";
import CustomersTable from "../Pages/Customers/Table";
import CustomersForms from "../Pages/Customers/Form";
import ContractorsTable from "../Pages/Contractors/Table";
import ContractorsForm from "../Pages/Contractors/Form";
import EmployeesForm from "../Pages/Employees/Form";
import EmployeesTable from "../Pages/Employees/Table";
import SettingsForm from "../Pages/Settings/Form";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Signin />,
  },
  {
    path: "/app",
    element: <Layout />,
    children: [
      {
        path: "/app/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/app/projects",
        element: <Project />,
      },
      {
        path: "/app/projects/add",
        element: <AddProject />,
      },
      {
        path: "/app/projects/edit/:id",
        element: <AddProject />,
      },
      {
        path: "/app/projects/view/:id",
        element: <ViewProject />,
      },
      // {
      //   path: "/app/projects/area",
      //   element: <Area />,
      // },
      {
        path: "/app/projects/area/add",
        element: <AddArea />,
      },
      {
        path: "/app/projects/area/add/:id",
        element: <AddArea />,
      },
      {
        path: "/app/projects/area/view",
        element: <ViewArea />,
      },
      // {
      //   path: "/app/projects/area/tasks",
      //   element: <Tasks />,
      // },
      {
        path: "/app/projects/area/task/add",
        element: <AddTasks />,
      },
      {
        path: "/app/projects/area/task/edit/:id",
        element: <AddTasks />,
      },
      {
        path: "/app/projects/area/task/view",
        element: <ViewTask />,
      },
      {
        path: "/app/projects/area/task/issues",
        element: <AddIssue />,
      },
      {
        path: "/app/projects/area/edit/issues/:id",
        element: <AddIssue />,
      },
      {
        path: "/app/projects/area/task/timesheet/estimate",
        element: <AddTimesheet />,
      },
      {
        path: "/app/projects/area/task/timesheet/add",
        element: <AddTimesheet />,
      },
      {
        path: "/app/projects/area/task/timesheet/edit/:id",
        element: <AddTimesheet />,
      },
      {
        path: "/app/projects/area/task/timesheet/view",
        element: <ViewTimesheet />,
      },
      {
        path: "/app/items",
        element: <ItemsTable />,
      },
      {
        path: "/app/items/add",
        element: <ItemForm />,
      },
      {
        path: "/app/items/edit/:id",
        element: <ItemForm />,
      },
      {
        path: "/app/items/category",
        element: <AddForm />,
      },
      {
        path: "/app/items/brand",
        element: <AddForm />,
      },
      {
        path: "/app/items/unit",
        element: <AddForm />,
      },
      {
        path: "/app/employees",
        element: <EmployeesTable />,
      },
      {
        path: "/app/employees/role",
        element: <AddForm />,
      },
      {
        path: "/app/employees/add",
        element: <EmployeesForm />,
      },
      {
        path: "/app/employees/edit/:id",
        element: <EmployeesForm />,
      },
      {
        path: "/app/customers",
        element: <CustomersTable />,
      },
      {
        path: "/app/customers/add",
        element: <CustomersForms />,
      },
      {
        path: "/app/customers/edit/:id",
        element: <CustomersForms />,
      },
      {
        path: "/app/suppliers",
        element: <SuppliersTable />,
      },
      {
        path: "/app/suppliers/add",
        element: <SuppliersForm />,
      },
      {
        path: "/app/suppliers/edit/:id",
        element: <SuppliersForm />,
      },
      {
        path: "/app/sales",
        element: <Sales />,
      },
      {
        path: "/app/sales/add",
        element: <AddQuotes />,
      },
      {
        path: "/app/sales/view",
        element: <InvoicePage />,
      },
      {
        path: "/app/quotes",
        element: <Quotes />,
      },
      {
        path: "/app/quotes/add",
        element: <AddQuotes />,
      },
      {
        path: "/app/quotes/view",
        element: <QuotationPage />,
      },
      {
        path: "/app/purchases",
        element: <Purchases />,
      },
      {
        path: "/app/purchases/add",
        element: <AddPurchase />,
      },
      {
        path: "/app/purchases/edit",
        element: <AddPurchase />,
      },
      {
        path: "/app/purchases/view",
        element: <PurchasePage />,
      },
      {
        path: "/app/contractors",
        element: <ContractorsTable />,
      },
      {
        path: "/app/contractors/add",
        element: <ContractorsForm />,
      },
      {
        path: "/app/contractors/edit/:id",
        element: <ContractorsForm />,
      },

      {
        path: "/app/reports",
        element: <Reports />,
      },
      {
        path: "/app/reports/projects",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/inventory",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/employees",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/customers",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/suppliers",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/quotes",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/sales",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/purchases",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/contractors",
        element: <ViewReports />,
      },

      {
        path: "/app/settings",
        element: <SettingsForm />,
      },
    ],
  },
]);

export default router;
