import React from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../Componets/Buttons/add";
import DotIcon from "../../Assets/Svg/dot";
import {
  FilterOutlined,
  FormOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import Newtable from "../Items/table";
import { DatePicker, Input, Select } from "antd";

const Quotes = () => {
  let navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const filters = [
    {
      value: "Active",
      label: (
        <div className="flex">
          <div className="text-green-500 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>Active</div>
        </div>
      ),
    },
    {
      value: "In-Active",
      label: (
        <div className="flex">
          <div className="text-red-500 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>In-Active</div>
        </div>
      ),
    },
    {
      value: "Paid",
      label: (
        <div className="flex">
          <div className="text-green-700 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>Paid</div>
        </div>
      ),
    },
    {
      value: "Unpaid",
      label: (
        <div className="flex">
          <div className="text-red-700 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>Unpaid</div>
        </div>
      ),
    },
  ];

  let data = [
    {
      number: "#QO-1001",
      customer: "QuantumScape Innovations",
      amount: "200",
      payment: 0,
      status: 1,
    },
    {
      number: "#QO-1002",
      customer: "BrightWave Solutions",
      amount: "250",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1003",
      customer: "NovaGenix Labs",
      amount: "320",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1004",
      customer: "PulseTech Innovations",
      amount: "450",
      payment: 0,
      status: 1,
    },
    {
      number: "#QO-1005",
      customer: "LuminaSphere Solutions",
      amount: "700",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1006",
      customer: "StellarCraft Technologies",
      amount: "40",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1007",
      customer: "InfinityWorks Inc.",
      amount: "30",
      payment: 0,
      status: 0,
    },
    {
      number: "#QO-1008",
      customer: "PulseTech Innovations",
      amount: "40",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1010",
      customer: "ZenithTech Corporation",
      amount: "500",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1011",
      customer: "NovaGenix Labs",
      amount: "700",
      payment: 1,
      status: 0,
    },
    {
      number: "#QO-1012",
      customer: "NovaGenix Labs",
      amount: "1100",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1013",
      customer: "NovaGenix Labs",
      amount: "40",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1014",
      customer: "AetherDyne Dynamics",
      amount: "200",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1015",
      customer: "CelestialTech Solutions",
      amount: "98",
      payment: 1,
      status: 0,
    },
    {
      number: "#QO-1016",
      customer: "VertexSynergy Technologies",
      amount: "76",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1017",
      customer: "QuantumScape Innovations",
      amount: "670",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1018",
      customer: "LuminaSphere Solutions",
      amount: "770",
      payment: 1,
      status: 1,
    },
    {
      number: "#QO-1019",
      customer: "LuminaSphere Solutions",
      amount: "390",
      payment: 0,
      status: 1,
    },
    {
      number: "#QO-1020",
      customer: "QuantumScape Innovations",
      amount: "569",
      payment: 1,
      status: 1,
    },
  ];

  const head = [
    {
      name: "Number",
      className: "p-5 text-gray-500",
      bodyClass: "p-3 text-black",
    },
    {
      name: "Customer",
      className: "p-3 text-gray-500",
      bodyClass: "p-3 text-gray-700",
    },
    {
      name: "Amount",
      className: "p-3 text-gray-500",
      bodyClass: "p-3 text-gray-700",
    },
    {
      name: "Status",
      className: "p-3 text-gray-500",
      bodyClass: "p-3 text-gray-700",
    },
    {
      name: "Action",
      className: "p-3 text-gray-500 text-center",
      bodyClass: "p-3 text-gray-700 text-center",
    },
  ];

  let body = [];
  data?.map((value, index) => {
    body?.push({
      Number: (
        <div className="flex">
          <div className="text-end my-auto pl-2">{value?.number}</div>
        </div>
      ),
      Customer: value?.customer,
      Amount: value?.amount,
      Payment: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.payment
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-red-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.payment ? "Paid" : "Unpaid"}</span>
        </div>
      ),
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.status
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-red-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.status ? "Active" : "Inactive"}</span>
        </div>
      ),
      Action: (
        <button>
          <FormOutlined className="text-gray-700 text-xl hover:text-[#2D53DA]" />
        </button>
      ),
    });
  });

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">Quotes</div>
        <div>
          <div className="flex ">
            <div className="pr-4">
              <Input
                className="search"
                placeholder=" Search"
                prefix={
                  <span className="text-gray-500 text-medium">
                    <SearchOutlined />
                  </span>
                }
              />
            </div>
            <div className="pr-4">
              <Select
                allowClear
                className="w-32"
                placeholder={
                  <span>
                    <SwapOutlined style={{ transform: "rotate(90deg)" }} />
                    <span className="pl-2">Sort</span>
                  </span>
                }
                options={[
                  { value: "Name (A-Z)", label: "Name (A-Z)" },
                  { value: "Name (Z-A)", label: "Name (Z-A)" },
                  { value: "Number (Acs)", label: "Number (Acs)" },
                  { value: "Number (Decs)", label: "Number (Decs)" },
                ]}
              />
            </div>
            <div className="pr-4">
              <Select
                allowClear
                className="w-32"
                placeholder={
                  <span>
                    <FilterOutlined />
                    <span className="pl-2">Filter</span>
                  </span>
                }
                options={filters}
              />
            </div>
            <div className="pr-4">
              <RangePicker />
            </div>
            <div>
              <AddButton onClick={() => navigate("/app/quotes/add")} />
            </div>
          </div>
        </div>
      </div>
      {/* Header Topbar End*/}
      <div className="p-4 bg-white border-b h-[40px] flex justify-end items-center text-xs text-semi-bold text-gray-500 uppercase">
        <div className="pr-3">TOTAL (15)</div>
        <div className="pr-3">ACTIVE (12)</div>
        <div className="pr-3">IN-ACTIVE (3)</div>
        <div className="pr-3">PAID (11)</div>
        <div className="pr-3">UNPAID (4)</div>
      </div>
      <div className="px-4 py-6">
        <Newtable head={head} body={body} path="/app/quotes/view" />
      </div>
    </div>
  );
};

export default Quotes;
