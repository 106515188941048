import React from "react";

const ContractorIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M13.03 1.87 2.04.2C1.53.12 1.01.26.62.59.23.93 0 1.42 0 1.93V23c0 .55.45 1 1 1h3.25v-5.25c0-.97.78-1.75 1.75-1.75h2.5c.97 0 1.75.78 1.75 1.75V24h4.25V3.6c0-.86-.62-1.59-1.47-1.73zM5.5 14.75H4a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3H4a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3H4a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3H4a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm5 9H9a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3H9a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3H9a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3H9a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zM22.62 10.842 15.5 9.351V24h6.75c.965 0 1.75-.785 1.75-1.75v-9.698c0-.826-.563-1.529-1.38-1.71zM20.25 21h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5zm0-3h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 0 1.5z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default ContractorIcon;
