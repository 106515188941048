import {
  LoadingOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const AddSalesForm = () => {
  let navigate = useNavigate();

  return (
    <div class="my-8">
      <div class="bg-white border rounded-md p-8 w-[80%] mx-auto">
        {/* detail */}
        <div>
          <div className="flex justify-between pb-5">
            <div className="text-medium  ">Details</div>
            <div className="text-medium text-2xl">
              <PrinterOutlined onClick={() => navigate("/app/sales/view")} />
              <div></div>
            </div>
          </div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>Date</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="Date"
              />
            </div>
            <div>Customer</div>
            <div>
              <Select
                className="w-full my-3 text-medium"
                placeholder="Select Customer"
                options={[
                  {
                    value: "BrightWave Solutions",
                    name: "BrightWave Solutions",
                  },
                  { value: "NovaGenix Labs", name: "NovaGenix Labs" },
                  { value: "InfinityWorks Inc.", name: "InfinityWorks Inc." },
                  { value: "NexusGen Biotech", name: "NexusGen Biotech" },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="text-medium  pb-3">Items</div>
        </div>
        {/* Payment */}
        <div className="pt-6">
          <div className="text-medium  pb-3">Payment</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Radio.Group
              className="w-full"
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="text-medium" value="a">
                Paid
              </Radio.Button>
              <Radio.Button className="text-medium" value="b">
                Unpaid
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>

        {/* Status */}
        <div className="pt-6">
          <div className="text-medium  pb-3">Satus</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Radio.Group
              className="w-full"
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="text-medium" value="a">
                Received
              </Radio.Button>
              <Radio.Button className="text-medium" value="b">
                Pending
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>

        {/* submit */}
        <div className="py-6">
          <div className="float-end">
            <Button
              className="mr-2 text-medium"
              onClick={() => navigate("/app/sales")}
            >
              Cancel
            </Button>
            <Button
              className="text-medium"
              type="primary"
              onClick={() => navigate("/app/sales")}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalesForm;
