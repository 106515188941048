import React from "react";

const CustomerIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g transform="matrix(1.0999999999999996,0,0,1.0999999999999996,-1.1999999999999904,-1.1999999999999904)">
        <path
          d="M23 19a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1 6 6 0 0 1 6-6h2a6 6 0 0 1 6 6zM16 4a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM7 4a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 15a7.94 7.94 0 0 1 2.35-5.65A5.43 5.43 0 0 0 7.44 13h-.88A5.57 5.57 0 0 0 1 18.56V19a1 1 0 0 0 1 1h5.18A3 3 0 0 1 7 19z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default CustomerIcon;
