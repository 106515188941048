import React from "react";
import LogoutIcon from "../../Assets/Svg/logout";
import { Logout } from "../../Controllers/Auth";
import { useNavigate } from "react-router-dom";

const LogoutButton = (props) => {
  let navigate = useNavigate();

  const handleLogout = async () => {
    const logout = await Logout();
    navigate("/");
  };

  return (
    <div
      className="border-t bg-white hover:bg-[#2D53DA] text-[#353F4F] hover:text-white text-medium  py-2 px-4 w-full cursor-pointer"
      onClick={handleLogout}
    >
      <div className="flex justify-center">
        <div>Logout</div>
        <div className="px-2 pt-0.5">
          <LogoutIcon />
        </div>
      </div>
    </div>
  );
};

export default LogoutButton;
