import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import TableImage from "../../../Componets/Image";
import Status from "../../../Componets/Status";
import ActionButton from "../../../Componets/Buttons/action";
import Table from "../../../Componets/Table";
import { get_all_items } from "../../../Controllers/Items";
import { toast } from "react-toastify";
import tables from "../../../Data/Tables/Items/Items/tables";
import headers from "../../../Data/Tables/Items/Items/headers";
import ValueTag from "../../../Componets/ValueTag";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const Inventory = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  let tag = (stock, notify) => {
    let data = "Stocked";

    if (stock == 0) {
      data = "Out of Stock";
    } else if (stock <= notify) {
      data = "Low";
    }

    return data;
  };

  let color = (stock, notify) => {
    let data = "green";

    if (stock == 0) {
      data = "red";
    } else if (stock <= notify) {
      data = "orange";
    }

    return data;
  };

  const [search, setSearch] = useState();
  const [sort, setSort] = useState();

  const formdata = (data) => {
    setSearch(data?.search);
    setSort(data?.sort);
  };

  const fetchData = async () => {
    try {
      const data = {
        search: search,
        sort: sort,
      };

      const items = await get_all_items(data);
      if (items?.status) {
        let data = [];

        console.log(items?.data);

        items?.data?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Stock: (
              <ValueTag
                value={value?.stock}
                tag={tag(value?.stock, value?.notify)}
                color={color(value?.stock, value?.notify)}
              />
            ),
            Price: value?.purchase_price,
            Category: value?.category?.name,
            Brands: value?.brands?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton edit={`/app/items/edit/${value?._id}`} delete="/" />
            ),
          });
        });

        setBody(data);
      } else {
        toast?.error(items?.message);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search, sort]);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default Inventory;
