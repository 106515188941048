import { Button, Checkbox, DatePicker, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import AddButton from "../../Componets/Buttons/add";
import CardTable from "./table";
import { useNavigate } from "react-router-dom";
import {
  ArrowUpOutlined,
  FilterOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import DotIcon from "../../Assets/Svg/dot";
import projects from "../../Data/projects.json";
import customers from "../../Data/customers.json";

const Project = () => {
  let navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const [table, setTable] = useState();

  const filters = [
    {
      value: "Active",
      label: (
        <div className="flex">
          <div className="text-cyan-500 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>Active</div>
        </div>
      ),
    },
    {
      value: "In-Active",
      label: (
        <div className="flex">
          <div className="text-yellow-500 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>In-Active</div>
        </div>
      ),
    },
    {
      value: "Completed",
      label: (
        <div className="flex">
          <div className="text-green-700 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>Completed</div>
        </div>
      ),
    },
    {
      value: "Cancelled",
      label: (
        <div className="flex">
          <div className="text-red-700 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>Cancelled</div>
        </div>
      ),
    },
    {
      value: "On-Time",
      label: (
        <div className="flex">
          <div className="text-green-500 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div>On-Time</div>
        </div>
      ),
    },
    {
      value: "Delayed",
      label: (
        <div className="flex">
          <div className="text-rose-500 my-auto pr-2">
            <DotIcon width={10} height={10} />
          </div>
          <div className="pr-2">Delayed</div>
        </div>
      ),
    },
  ];

  const getCustomer = (id) => {
    let data = {};

    customers?.map((value, index) => {
      if (value?.id == id) {
        data = value;
      }
    });

    return data;
  };

  const fetchData = async () => {
    let data = [];
    let customer = [];

    projects?.map((value, index) => {
      let customer = getCustomer(value?.customer);
      data?.push({ ...value, customer: customer });
    });

    console.log(data);

    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="bg-white border-b">
        <div className="p-4 flex justify-between">
          <div className="">Projects</div>
          <div>
            <div className="flex ">
              <div className="pr-4">
                <Input
                  className="search"
                  placeholder=" Search"
                  prefix={
                    <span className="text-gray-500 text-medium">
                      <SearchOutlined />
                    </span>
                  }
                />
              </div>
              <div className="pr-4">
                <Select
                  allowClear
                  className="w-32"
                  placeholder={
                    <span>
                      <SwapOutlined style={{ transform: "rotate(90deg)" }} />
                      <span className="pl-2">Sort</span>
                    </span>
                  }
                  options={[
                    { value: "Name (A-Z)", label: "Name (A-Z)" },
                    { value: "Name (Z-A)", label: "Name (Z-A)" },
                    { value: "Est. Cost (Acs)", label: "Est. Cost (Acs)" },
                    { value: "Est. Cost (Decs)", label: "Est. Cost (Decs)" },
                    { value: "Cost (Acs)", label: "Cost (Acs)" },
                    { value: "Cost (Decs)", label: "Cost (Decs)" },
                  ]}
                />
              </div>
              <div className="pr-4">
                <Select
                  allowClear
                  className="w-32"
                  placeholder={
                    <span>
                      <FilterOutlined />
                      <span className="pl-2">Filter</span>
                    </span>
                  }
                  options={filters}
                />
              </div>
              <div className="pr-4">
                <RangePicker />
              </div>
              <div>
                <AddButton onClick={() => navigate("/app/projects/add")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white border-b h-[40px] flex justify-end items-center text-xs text-semi-bold text-gray-500 uppercase">
        <div className="pr-3">TOTAL (6)</div>
        <div className="pr-3">ACTIVE (3)</div>
        <div className="pr-3">IN-ACTIVE (1)</div>
        <div className="pr-3">COMPLETED (1)</div>
        <div className="pr-3">CANCELLED (1)</div>
        <div className="pr-3">ON-TIME (4)</div>
        <div>Delayed (2)</div>
      </div>
      <div className="px-4 py-6">
        <CardTable table={table} />
      </div>
    </div>
  );
};

export default Project;
