import React from "react";
import DotIcon from "../../Assets/Svg/dot";
import { FormOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AddButton from "../../Componets/Buttons/add";

const Newtable = (props) => {
  let navigate = useNavigate();
  let head = props?.head;
  let body = props?.body;
  let path = props?.path;
  let foot = props?.foot;

  return (
    <table className="bg-white w-full rounded-md">
      <thead>
        <tr className="border-b">
          {head?.map((value, index) => {
            return <td className={value?.className}>{value?.name}</td>;
          })}
        </tr>
      </thead>
      <tbody>
        {body?.map((value, index) => {
          return (
            <tr
              className="border-b hover:bg-gray-200"
              onClick={(e) => {
                e?.stopPropagation();
                navigate(path);
              }}
            >
              {head?.map((val, ind) => {
                return (
                  Object?.keys(value)?.includes(val?.name) && (
                    <td className={val?.bodyClass}>{value?.[val?.name]}</td>
                  )
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          {foot?.map((value, index) => {
            return <td className={value?.className}>{value?.value}</td>;
          })}
        </tr>
      </tfoot>
    </table>
  );
};

export default Newtable;
