import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import customers from "../../../Data/customers.json";
import projects from "../../../Data/projects.json";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const AddProjectForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined className="text-gray-500" />
      <div className="text-medium text-gray-500" style={{ marginTop: 8 }}>
        Upload
      </div>
    </button>
  );

  const [form, setForm] = useState({
    image: "",
    customer: null,
    customer_options: [],
    start: "",
    end: "",
    cost: "",
    status: "Active",
  });

  const handleSave = (e) => {
    e?.preventDefault();
    // toast?.success("Project created");
    toast?.error("408: Request Timeout");
    navigate(-1);
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    navigate(-1);
  };

  const fetchdata = async () => {
    if (id) {
      let project = {};
      let customer = {};
      let options = [];

      projects?.map((value, index) => {
        if (value?.id == id) {
          project = value;
        }
      });

      customers?.map((value, index) => {
        if (value?.id == project?.customer) {
          customer = value?.name;
        }
        options?.push({ value: value?.name, label: value?.name });
      });

      form.image = project?.image;
      form.name = project?.name;
      form.customer = customer;
      form.customer_options = options;
      form.start = dayjs(project?.start);
      form.end = dayjs(project?.form);
      form.status = project?.status;
      form.cost = project?.cost;
      setForm({ ...form });
    } else {
      let options = [];
      customers?.map((value, index) => {
        options?.push({ value: value?.name, label: value?.name });
      });
      form.customer_options = options;
      setForm({ ...form });
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <div class="my-8">
      <form onSubmit={handleSave} onReset={handleCancel}>
        <div class="bg-white border rounded-md p-8 w-[50%] mx-auto">
          {/* detail */}
          <div>
            <div className="text-medium  pb-3">Details</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader mt-1 mb-3 w-full"
                  showUploadList={false}
                >
                  {form?.image ? (
                    <img
                      src={form?.image}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              <div>Name</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Ex : Bathroom Remodel"
                  value={form?.name}
                />
              </div>
            </div>
          </div>
          {/* stock */}
          <div className="pt-6">
            <div className="text-medium  pb-3">Customer</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>Name</div>
              <div>
                <Select
                  className="w-full my-3 text-medium"
                  placeholder="Select Project Customer"
                  allowClear={true}
                  showSearch={true}
                  value={form?.customer}
                  options={form?.customer_options}
                />
              </div>
            </div>
          </div>
          {/* price */}
          <div className="pt-6">
            <div className="text-medium  pb-3">Date</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>Start</div>
              <div>
                <DatePicker
                  className="w-full my-3 text-medium"
                  placeholder="From"
                  value={form?.start}
                />
              </div>
              <div>End</div>
              <div>
                <DatePicker
                  className="w-full my-3 text-medium"
                  placeholder="To"
                  value={form?.end}
                />
              </div>
            </div>
          </div>
          {/* cost */}
          <div className="pt-6">
            <div className="text-medium  pb-3">Estimated Cost</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>Cost</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Estimated Project Cost"
                  value={form?.cost}
                />
              </div>
            </div>
          </div>
          {/* area */}
          <div className="pt-6">
            <div className="text-medium  pb-3">Address</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>Area</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Project Area"
                />
              </div>
              <div>City</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Project City"
                />
              </div>
              <div>State</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Project State"
                />
              </div>
              <div>Country</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Project Country"
                />
              </div>
            </div>
          </div>
          {/* Status */}
          <div className="pt-6">
            <div className="text-medium  pb-3">Country</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <Radio.Group
                className="w-full"
                defaultValue="Active"
                value={form?.status}
                buttonStyle="solid"
                onChange={(e) => {
                  form.status = e?.target?.value;
                  setForm({ ...form });
                }}
              >
                <Radio.Button className="text-medium" value="Active">
                  Active
                </Radio.Button>
                <Radio.Button className="text-medium" value="In-Active">
                  Inactive
                </Radio.Button>
                <Radio.Button className="text-medium" value="Cancelled">
                  Cancelled
                </Radio.Button>
                <Radio.Button className="text-medium" value="Completed">
                  Completed
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          {/* submit */}
          <div className="py-6">
            <div className="float-end">
              <Button className="mr-2 text-medium" htmlType="reset">
                Cancel
              </Button>
              <Button className="text-medium" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProjectForm;
