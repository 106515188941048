import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const create_customer = async (customer) => {
  try {
    let data = {
      name: customer?.name,
      email: customer?.email,
      phone: customer?.phone,
      area: customer?.area,
      city: customer?.city,
      state: customer?.state,
      country: customer?.country,
      status: customer?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-customer`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_customer = async (customer) => {
  try {
    let data = {
      id: customer?.id,
      name: customer?.name,
      email: customer?.email,
      phone: customer?.phone,
      area: customer?.area,
      city: customer?.city,
      state: customer?.state,
      country: customer?.country,
      status: customer?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-customer`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_customer = async (customer) => {
  try {
    const id = customer?.id;

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${APIURL}/get-customer/${id}`, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_customers = async (customer) => {
  try {
    const data = {
      search: customer?.search,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-customers`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { create_customer, update_customer, get_customer, get_all_customers };
