import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const create_contractor = async (contractor) => {
  try {
    let data = {
      name: contractor?.name,
      email: contractor?.email,
      phone: contractor?.phone,
      catlog: JSON?.stringify(contractor?.catlog),
      tax: contractor?.tax,
      area: contractor?.area,
      city: contractor?.city,
      state: contractor?.state,
      country: contractor?.country,
      status: contractor?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-contractor`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_contractor = async (contractor) => {
  try {
    let data = {
      id: contractor?.id,
      name: contractor?.name,
      email: contractor?.email,
      phone: contractor?.phone,
      catlog: JSON?.stringify(contractor?.catlog),
      tax: contractor?.tax,
      area: contractor?.area,
      city: contractor?.city,
      state: contractor?.state,
      country: contractor?.country,
      status: contractor?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-contractor`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_contractor = async (contractor) => {
  try {
    const id = contractor?.id;

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${APIURL}/get-contractor/${id}`, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_contractors = async (contractor) => {
  try {
    const data = {
      search: contractor?.search,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-contractors`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_contractor,
  update_contractor,
  get_contractor,
  get_all_contractors,
};
