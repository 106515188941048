import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const PlusButton = (props) => {
  return (
    <Button
      className="text-bold bg-[#2D53DA] border-[#2D53DA] flex items-center justify-center p-2"
      {...props}
    >
      <PlusOutlined className="text-white" style={{ fontWeight: "400" }} />
    </Button>
  );
};

export default PlusButton;
