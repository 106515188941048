import {
  CalendarOutlined,
  EnvironmentOutlined,
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../../Componets/Buttons/add";
import { Progress } from "antd";
import DotIcon from "../../../../Assets/Svg/dot";
import Newtable from "../../../Items/table";

const ViewArea = () => {
  let navigate = useNavigate();

  let AreaHead = [
    {
      name: "Area",
      className: "p-5 text-gray-500  text-sm",
      bodyClass: "p-3 text-black text-medium text-sm",
    },
    {
      name: "Number",
      className: "p-5 text-gray-500 text-sm ",
      bodyClass: "p-3  text-black text-medium  text-sm ",
    },
    {
      name: "Cost",
      className: "p-5 text-gray-500 text-sm text-center",
      bodyClass: "p-3  text-black text-medium text-sm text-center",
    },
    {
      name: "Progress",
      className: "p-3 text-gray-500 text-sm text-center",
      bodyClass: "p-3  text-black text-medium  text-sm ",
    },
    {
      name: "Status",
      className: "pr-5 text-gray-500 text-sm text-right",
      bodyClass: "pr-5  text-black text-medium  text-sm text-right",
    },
  ];

  const AreaBody = [
    {
      Area: (
        <div className="flex">
          <img
            className="w-[50px] h-[50px] rounded-md"
            src="https://www.thespruce.com/thmb/QJhy2Tfy3EhDwWOF_PzyZvub16o=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/BB_DanversHome-62-5c294babc9e77c000128a45e.jpg"
          />
          <div className="text-end my-auto pl-2">Basement</div>
        </div>
      ),
      Number: "#ANO18500",
      Cost: "300",
      Progress: (
        <div className="flex justify-center">
          <div>
            <Progress
              type="circle"
              percent={65}
              size={25}
              format={(percent) => ``}
              strokeColor="#10B981"
              strokeWidth={16}
            />
          </div>
          <div className="my-auto px-2">65%</div>
        </div>
      ),
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={"anticon anticon-up-circle text-[#10B981]"}
          >
            <DotIcon />
          </span>
          <span className="pl-1">Active</span>
        </div>
      ),
    },
  ];

  const data = [
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK8ejF9q1bjzuA2oKLkeGVqazqxXHZtG6R_zvFa4sAjw&s",
      name: "Parking",
      progress: 25,
      status: 1,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2jvqNX5Jc_j1_Ib9LecF7FH5Kf2yKkH3zEYSa2i5UMA&s",
      name: "Security Room",
      progress: 45,
      status: 1,
    },
    {
      image:
        "https://png.pngtree.com/thumb_back/fh260/background/20230704/pngtree-modern-loft-bathroom-interior-with-white-table-top-for-montaging-your-image_3742886.jpg",
      name: "Washroom",
      progress: 25,
      status: 1,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVxAdGt-uLcLAIr6Iogio6ASrzTSbABMzlszMadxdRmw&s",
      name: "Lift Area",
      progress: 55,
      status: 1,
    },
    {
      image:
        "https://media.istockphoto.com/id/1302554065/photo/organised-pantry-items-in-storage-room-with-nonperishable-food-staples-preserved-foods-healty.jpg?s=612x612&w=0&k=20&c=WrFSFJyXAaZjarjf_5-2cbGrzroYAvLPrtluLFm7_YA=",
      name: "Storage Room",
      progress: 70,
      status: 1,
    },
  ];

  let head = [
    {
      name: "Task",
      className: "p-5 text-gray-500 text-sm",
      bodyClass: "p-3 text-black text-sm",
    },
    {
      name: "Cost",
      className: "p-5 text-gray-500 text-sm",
      bodyClass: "p-3 text-black text-sm",
    },
    {
      name: "Progress",
      className: "p-3 text-gray-500 text-sm",
      bodyClass: "p-3 text-gray-700 text-sm",
    },
    {
      name: "Status",
      className: "p-3 text-gray-500 text-sm",
      bodyClass: "p-3 text-gray-700 text-sm",
    },
    {
      name: "Action",
      className: "p-3 text-gray-500 text-center text-sm",
      bodyClass: "p-3 text-gray-700 text-center text-sm",
    },
  ];

  const body = [];
  data?.map((value, index) => {
    body?.push({
      Task: (
        <div className="flex">
          <img className="w-[50px] h-[50px] rounded-md" src={value?.image} />
          <div className="text-end my-auto pl-2">{value?.name}</div>
        </div>
      ),
      Cost: value?.name,
      Progress: (
        <div className="flex">
          <div>
            <Progress
              type="circle"
              percent={value?.progress}
              size={25}
              format={(percent) => ``}
              strokeColor="#10B981"
              strokeWidth={16}
            />
          </div>
          <div className="my-auto px-2">{value?.progress}%</div>
        </div>
      ),
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.status
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-red-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.status ? "Active" : "Inactive"}</span>
        </div>
      ),
      Action: (
        <button>
          <FormOutlined className="text-gray-500 text-xl hover:text-[#2D53DA]" />
        </button>
      ),
    });
  });

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects")}
          >
            Projects
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects/view")}
          >
            View
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span className="">Area</span>
        </div>
        <div>
          <AddButton
            name="Task"
            onClick={() => navigate("/app/projects/area/task/add")}
          />
        </div>
      </div>

      <div className="px-5 pt-6">
        <span className="text-base text-medium text-black pr-2">
          Envol Office Remodel
        </span>
        <span className="bg-gray-200 text-sm px-2 py-1 rounded-lg text-gray-500">
          #PNO18500
        </span>
      </div>

      {/* <div className="px-4 pt-4">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between">
            <div>
              <div className="flex">
                <img
                  src="https://www.milgard.com/sites/milgard/files/u/u23836/arbib_hughey_-_winter_park_house_whit_preston_002.jpg"
                  className="w-[125px] h-[125px] rounded-md"
                />
                <div className="pl-4 text-sm text-gray-700">
                  <span className="text-base text-medium text-black pr-2">
                    Envol Office Remodel
                  </span>
                  <span className="bg-gray-200 text-sm px-2 py-1 rounded-lg text-gray-500">
                    #PNO18500
                  </span>
                  <div className="pt-1">
                    <UserOutlined />
                    <span className="pl-2 ">Envol Engineering LLC</span>
                  </div>
                  <div>
                    <PhoneOutlined style={{ transform: "rotate(90deg)" }} />
                    <span className="pl-2">(968) 55019844</span>
                  </div>
                  <div>
                    <MailOutlined />
                    <span className="pl-2">info@eellc.com</span>
                  </div>
                  <div>
                    <EnvironmentOutlined />
                    <span className="pl-2">4565 Bousher.Muscat,Oman 1760</span>
                  </div>
                  <div>
                    <CalendarOutlined />
                    <span className="px-2">Sep 17 2024 - Oct 12 2024</span>
                    <span className="px-2 text-xs text-medium bg-green-500 text-white rounded-full">
                      On-Time
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <FormOutlined
                  className="text-gray-500 text-xl hover:text-[#2D53DA]"
                  onClick={() => navigate("/app/projects/add")}
                />
              </div>
            </div>
          </div>
          <div className="pt-4">
            <Progress
              className="text-medium"
              strokeColor="#10B981"
              percent={30}
              strokeWidth={6}
            />
          </div>
        </div>
      </div> */}

      {/* data */}
      {/* <div className="px-4 pt-3">
        <div className="bg-white rounded-md">
          <div className="p-4 flex justify-between">
            <div className="text-medium text-sm">
              <div className="text-center uppercase">Area (24)</div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 6
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 18
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center uppercase">Tasks (48)</div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 8
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 40
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center uppercase">Timesheet (48)</div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 8
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 40
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center uppercase">Days (90)</div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 22
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 68
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center uppercase">Issues (15)</div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 4
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 11
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* info */}
      {/* <div className="px-4 pt-3">
        <div className="bg-white rounded-md">
          <div className="p-4 flex justify-between">
            <div className="text-medium text-sm">
              <div className="text-center text-gray-500">Employees</div>
              <div className="text-center text-semi-bold pt-2 text-blue-500">
                45
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Estimated Cost</div>
              <div className="text-center text-semi-bold pt-2 text-green-500">
                1245
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Employees Cost</div>
              <div className="text-center text-semi-bold pt-2 text-rose-300">
                -300
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Inventory</div>
              <div className="text-center text-semi-bold pt-2 text-rose-300">
                -400
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Contractors</div>
              <div className="text-center text-semi-bold pt-2 text-rose-300">
                -250
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Total Expense</div>
              <div className="text-center text-semi-bold pt-2 text-red-500">
                -950
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center text-gray-500">Revenue</div>
              <div className="text-center text-semi-bold pt-2 text-green-500">
                +245
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center text-gray-500">Status</div>
              <div className="text-center text-semi-bold pt-2 text-cyan-500">
                <span className="pl-1">Active</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Header Topbar End*/}
      <div>
        {/* newtable */}
        <div className="pt-6">
          <div className="px-4">
            <Newtable
              head={AreaHead}
              body={AreaBody}
              path="/app/projects/area/add"
            />
            <div className="py-3">
              <div className="bg-white rounded-md">
                <div className="p-4 flex justify-between">
                  {/* <div className="text-medium text-sm">
                    <div className="text-center uppercase">Area (24)</div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Remaining : 6
                    </div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Completed : 18
                    </div>
                  </div> */}
                  <div className="text-medium text-sm">
                    <div className="text-center uppercase">Tasks (48)</div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Remaining : 8
                    </div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Completed : 40
                    </div>
                  </div>
                  <div className="text-medium text-sm">
                    <div className="text-center uppercase">Timesheet (48)</div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Remaining : 8
                    </div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Completed : 40
                    </div>
                  </div>
                  <div className="text-medium text-sm">
                    <div className="text-center uppercase">Days (90)</div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Remaining : 22
                    </div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Completed : 68
                    </div>
                  </div>
                  <div className="text-medium text-sm">
                    <div className="text-center uppercase">Issues (15)</div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Remaining : 4
                    </div>
                    <div className="text-center text-xs text-medium pt-2 text-gray-500">
                      Completed : 11
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                <div className="bg-white pt-4">
                  <div className="p-4 flex justify-between">
                    <div className="text-medium text-sm">
                      <div className="text-center text-gray-500">Employees</div>
                      <div className="text-center text-semi-bold pt-2 text-blue-500">
                        11
                      </div>
                    </div>
                    <div className="text-medium text-sm">
                      <div className="text-center  text-gray-500">
                        Estimated Cost
                      </div>
                      <div className="text-center text-semi-bold pt-2 text-green-500">
                        154
                      </div>
                    </div>
                    <div className="text-medium text-sm">
                      <div className="text-center  text-gray-500">
                        Employees Cost
                      </div>
                      <div className="text-center text-semi-bold pt-2 text-rose-300">
                        -70
                      </div>
                    </div>
                    <div className="text-medium text-sm">
                      <div className="text-center  text-gray-500">
                        Inventory
                      </div>
                      <div className="text-center text-semi-bold pt-2 text-rose-300">
                        -50
                      </div>
                    </div>
                    <div className="text-medium text-sm">
                      <div className="text-center  text-gray-500">
                        Contractors
                      </div>
                      <div className="text-center text-semi-bold pt-2 text-rose-300">
                        -20
                      </div>
                    </div>
                    <div className="text-medium text-sm">
                      <div className="text-center  text-gray-500">
                        Total Expense
                      </div>
                      <div className="text-center text-semi-bold pt-2 text-red-500">
                        -140
                      </div>
                    </div>
                    <div className="text-medium text-sm">
                      <div className="text-center text-gray-500">Revenue</div>
                      <div className="text-center text-semi-bold pt-2 text-green-500">
                        +14
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* newtable */}
        <div className="px-4 pb-4 pt-4">
          <Newtable
            head={head}
            body={body}
            path="/app/projects/area/task/view"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewArea;
