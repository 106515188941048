import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const AddButton = (props) => {
  let name = props?.name ? props?.name : "Add";
  return (
    <Button className="text-[#2D53DA] border-[#2D53DA]" {...props}>
      <div className="flex">
        <div>
          <PlusOutlined style={{ width: "0.9em" }} />
        </div>
        <div className="px-2 text-regular">{name}</div>
      </div>
    </Button>
  );
};

export default AddButton;
