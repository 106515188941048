import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../Componets/Header";
import { get_all_items } from "../../../Controllers/Items";
import forms from "../../../Data/Forms/Suppliers/Suppliers/forms";
import headers from "../../../Data/Forms/Suppliers/Suppliers/headers";
import {
  create_supplier,
  get_supplier,
  update_supplier,
} from "../../../Controllers/Suppliers";

const SuppliersForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  let form = forms;
  const header = headers;

  const [value, setValue] = useState(form);

  const formdata = async (data) => {
    if (data?.status) {
      const supplier = id
        ? await update_supplier({ ...data?.data, id: id })
        : await create_supplier(data?.data);
      if (supplier?.status) {
        toast?.success(supplier?.message);
        navigate("/app/suppliers");
      } else {
        toast?.error(supplier?.message);
      }
    }
  };

  const fetchData = async () => {
    if (id) {
      const data = {
        id,
      };
      const supplier = await get_supplier(data);
      if (supplier?.status) {
        let options = [];
        supplier?.data?.items?.map((value, index) => {
          options?.push({ value: value?._id, label: value?.name });
        });

        let supplier_data = supplier?.data?.supplier;

        let name = supplier_data?.name ? supplier_data?.name : "";
        let email = supplier_data?.email ? supplier_data?.email : "";
        let phone = supplier_data?.phone ? supplier_data?.phone : "";
        let catlog = supplier_data?.catlog
          ? JSON.parse(supplier_data?.catlog)
          : null;
        let tax = supplier_data?.tax ? supplier_data?.tax : "";
        let area = supplier_data?.area ? supplier_data?.area : "";
        let city = supplier_data?.city ? supplier_data?.city : "";
        let state = supplier_data?.state ? supplier_data?.state : "";
        let country = supplier_data?.country ? supplier_data?.country : "";
        let status = supplier_data?.status ? supplier_data?.status : 0;

        value[0].options[0].form.value = name;

        value[1].options[0].form.value = email;
        value[1].options[1].form.value = phone;

        value[2].options[0].form.value = catlog;
        value[2].options[0].form.options = options;

        value[3].options[0].form.value = tax;

        value[4].options[0].form.value = area;
        value[4].options[1].form.value = city;
        value[4].options[2].form.value = state;
        value[4].options[3].form.value = country;

        value[5].options[0].form.value = status;

        setValue([...value]);
      } else {
        toast?.error(supplier?.message);
      }
    } else {
      const items = await get_all_items();
      if (items?.status) {
        let options = [];
        items?.data?.map((value, index) => {
          options?.push({ value: value?._id, label: value?.name });
        });

        value[2].options[0].form.options = options;

        setValue([...value]);
      } else {
        toast?.error(items?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Header header={header}>
      <Forms form={value} formdata={formdata} />
    </Header>
  );
};

export default SuppliersForm;
