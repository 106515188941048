import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const Login = async (signup) => {
  try {
    const data = {
      email: signup?.email,
      password: signup?.password,
    };

    const response = await axios.post(`${APIURL}/signin`, data);

    if (response?.data?.status) {
      localStorage.setItem("user", response?.data?.data?.token);
    }
    return response?.data;
  } catch (error) {
    return error;
  }
};

const Logout = async () => {
  try {
    localStorage.clear();
  } catch (errors) {
    return errors;
  }
};

export { Login, Logout };
