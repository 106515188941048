const headers = {
  name: "Suppliers",
  search: true,
  upload: false,
  download: false,
  button: "/app/suppliers/add",
  options: {
    sort: [
      { value: "Name (A-Z)", label: "Name (A-Z)" },
      { value: "Name (Z-A)", label: "Name (Z-A)" },
    ],
    sorts: [
      {
        name: "Staus",
        options: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
        ],
      },
      {
        name: "Product",
        options: null,
      },
    ],
    total: [
      { value: "10", label: "Total" },
      { value: "3", label: "Active" },
      { value: "1", label: "Inactive" },
    ],
  },
};

export default headers;
