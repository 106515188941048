import {
  CalendarOutlined,
  EnvironmentOutlined,
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  UpCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import Radicalbar from "../../Componets/Graph/radicalbar";
import { Progress } from "antd";
import DotIcon from "../../Assets/Svg/dot";
import { useNavigate } from "react-router-dom";
import projects from "../../Data/projects.json";

const CardTable = (props) => {
  let navigate = useNavigate();

  const data = [
    {
      image:
        "https://www.milgard.com/sites/milgard/files/u/u23836/arbib_hughey_-_winter_park_house_whit_preston_002.jpg",
      pno: "#PNO18500",
      name: "Envol Office Remodel",
      cost: "1250.500",
      revenue: "750.200",
      completion: 75,
      status: 1,
      progress: 1,
      date: "Jan 17 2024 - Oct 12 2024",
      customer: [
        {
          name: "Envol Engineering LLC",
          email: "info@eellc.com",
          phone: "(968) 55019844",
          location: "4565 Bousher,Muscat,Oman 1760",
        },
      ],
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxPHHnQsANguQVKXWXBWjMmeBAA2M1RtAVEDj5Y2tolg&s",
      pno: "#PNO18501",
      name: "Nijum's House Remodel",
      cost: "2250.000",
      revenue: "550.200",
      completion: 0,
      status: 1,
      progress: 0,
      date: "Feb 12 2024 - APR 12 2024",
      customer: [
        {
          name: "Vancour Service Management",
          email: "admi@vnc.com",
          phone: "(968) 87654567",
          location: "CBD Ruwi,Muscat,Oman 1760",
        },
      ],
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsyfNidAFfBprP9XfDouSfKfOJwcukbZhCu9K_A5YKsw&s",
      pno: "#PNO18502",
      name: "Brown's House Gardening",
      cost: "955.500",
      revenue: "220.200",
      completion: 45,
      status: 0,
      progress: 3,
      date: "Mar 12 2024 - Aug 12 2024",
      customer: [
        {
          name: "Brown Kayle",
          email: "brown55@gmail.com",
          phone: "(968) 78954321",
          location: "South Ghubra,Muscat,Oman 1760",
        },
      ],
    },
    {
      image:
        "https://cdn.midwesthome.com/wp-content/uploads/sites/2/2023/07/mh0323_HR_IDEAS_build_remodel_CA-Love_Jim-Kruger_62e.jpg",
      pno: "#PNO18503",
      name: "Henry Roof Rebuild",
      cost: "980.500",
      revenue: "1100.200",
      completion: 80,
      status: 1,
      progress: 2,
      date: "Dec 12 2023 - Aug 12 2024",
      customer: [
        {
          name: "Henry Williams",
          email: "williams1@yahooh.com",
          phone: "(968) 55019844",
          location: "Al-Wadi Seeb,Muscat,Oman 1760",
        },
      ],
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxYmwo6xLYOYGF_mCT1w_WUkpN3l0M8VvtboXmowb7Ng&s",
      pno: "#PNO18504",
      name: "Richard House Construction",
      cost: "3350.500",
      revenue: "7500.200",
      completion: 35,
      status: 1,
      progress: 1,
      date: "Feb 28 2023 - Nov 12 2024",
      customer: [
        {
          name: "Richard",
          email: "richardk55@gmail.com",
          phone: "(968) 55019844",
          location: "Al-khoud,Muscat,Oman 1760",
        },
      ],
    },
    {
      image:
        "https://capitalrenovationsgroup.com/wp-content/uploads/2020/09/modern-home-remodel-devonshire-dallas-texas-exterior-front.jpg",
      pno: "#PNO18505",
      name: "Daniel Matthew House Interiors",
      cost: "500.500",
      revenue: "200.455",
      completion: 70,
      status: 0,
      progress: 1,
      date: "Jan 15 2023 - Mar 11 2024",
      customer: [
        {
          name: "Daniel Matthew",
          email: "mattewdaniel22@gmail.com",
          phone: "(968) 55019844",
          location: "Park Qurum,Muscat,Oman 1760",
        },
      ],
    },
  ];

  const getEdit = (e, id) => {
    e?.stopPropagation();
    navigate(`/app/projects/edit/${id}`);
  };

  return (
    <table className="w-full">
      <tbody className="">
        {/* {data?.map((value, index) => { */}
        {props?.table?.map((value, index) => {
          return (
            <tr
              className="m-0 p-0 project"
              onClick={() => navigate(`/app/projects/view/${value?.id}`)}
            >
              <td className="m-0 p-0 ">
                <div className="p-4 bg-white rounded-tl-md rounded-bl-md my-2 cursor-pointer card-hover">
                  <div className="flex">
                    {/* image */}
                    <div className="">
                      <img
                        src={value?.image}
                        className="rounded-md w-[130px] h-[130px]"
                      />
                    </div>
                    {/* name & customer*/}
                    <div className="px-4">
                      {/* name */}
                      <div>
                        <div>
                          <span className="pr-2 text-black">{value?.name}</span>
                          <span className="bg-gray-200 text-sm px-2 py-1 rounded-lg text-gray-500">
                            {value?.pno}
                          </span>
                        </div>
                      </div>
                      {/* customer */}
                      <div className="pt-2 text-gray-700">
                        {/* customer name */}
                        <div className="flex">
                          <div>
                            <UserOutlined />
                          </div>
                          <div className="text-sm px-2">
                            {value?.customer?.name}
                          </div>
                        </div>
                        {/* customer contact */}
                        <div className="flex">
                          <div>
                            <div className="flex">
                              <div className="">
                                <PhoneOutlined
                                  style={{ transform: "rotate(90deg)" }}
                                />
                              </div>
                              <div className="text-sm px-2">
                                {value?.customer?.phone}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex pl-2">
                              <div className="">
                                <MailOutlined />
                              </div>
                              <div className="text-sm px-2">
                                {value?.customer?.email}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* customer address */}
                        <div className="flex">
                          <div>
                            <EnvironmentOutlined />
                          </div>
                          <div className="text-sm px-2">
                            {value?.customer?.area +
                              ", " +
                              value?.customer?.city +
                              ", " +
                              value?.customer?.state +
                              ", " +
                              value?.customer?.country}
                          </div>
                        </div>
                        {/* date */}
                        <div className="flex">
                          <div>
                            <CalendarOutlined />
                          </div>
                          <div className="text-sm px-2 flex">
                            <div>
                              {value?.start}&nbsp;-&nbsp;{value?.start}
                            </div>
                            <div className="ml-2">
                              {value?.time == "On-Time" ? (
                                <span className="px-2 text-xs text-medium bg-green-500 text-white rounded-full">
                                  On-Time
                                </span>
                              ) : (
                                <span className="px-2 text-xs text-medium bg-rose-500 text-white rounded-full">
                                  Delayed
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              {/* project cost */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Est. cost</div>
                    <div className="pt-3 text-gray-700">
                      {value?.cost?.toFixed(3)}
                    </div>
                  </div>
                </div>
              </td>
              {/* project revenue */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Est. expense</div>
                    <div className="pt-3 text-gray-700">
                      {value?.expense?.toFixed(3)}
                    </div>
                  </div>
                </div>
              </td>
              {/* timeline */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Progress</div>
                    <div className="pt-3 flex text-gray-700 ">
                      <div>
                        <Progress
                          type="circle"
                          percent={value?.progress}
                          size={25}
                          format={(percent) => ``}
                          strokeColor="#10B981"
                          strokeWidth={16}
                        />
                      </div>
                      <div className="my-auto px-2">{value?.progress}%</div>
                    </div>
                  </div>
                </div>
              </td>
              {/* edit */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white rounded-tr-md rounded-br-md card-hover">
                  <div className="h-[130px] flex flex-col justify-between">
                    <div className="text-end">
                      <button>
                        <FormOutlined
                          className="text-gray-500 text-xl hover:text-[#2D53DA]"
                          onClick={(e) => getEdit(e, value?.id)}
                        />
                      </button>
                    </div>
                    <div className="text-end text-sm">
                      {value?.status == "Active" ? (
                        <>
                          <span
                            role="img"
                            aria-label="up-circle"
                            className="anticon anticon-up-circle text-cyan-500"
                          >
                            <DotIcon />
                          </span>
                          <span className="pl-1">Active</span>
                        </>
                      ) : value?.status == "In-Active" ? (
                        <>
                          <span
                            role="img"
                            aria-label="up-circle"
                            className="anticon anticon-up-circle text-yellow-500"
                          >
                            <DotIcon />
                          </span>
                          <span className="pl-1">In-Active</span>
                        </>
                      ) : value?.status == "Completed" ? (
                        <>
                          <span
                            role="img"
                            aria-label="up-circle"
                            className="anticon anticon-up-circle text-green-700"
                          >
                            <DotIcon />
                          </span>
                          <span className="pl-1">Completed</span>
                        </>
                      ) : (
                        <>
                          <span
                            role="img"
                            aria-label="up-circle"
                            className="anticon anticon-up-circle text-red-700"
                          >
                            <DotIcon />
                          </span>
                          <span className="pl-1">Cancelled</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CardTable;
