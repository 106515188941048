import { Input, Select, Space } from "antd";
import { Option } from "antd/es/mentions";
import React from "react";

const SettingsForm = () => {
  return (
    <Space.Compact block className="w-full">
      <Select className="w-[25%]" defaultValue="Zhejiang" allowClear showSearch>
        <Option value="Zhejiang">Zhejiang</Option>
        <Option value="Jiangsu">Jiangsu</Option>
      </Select>
      <Select className="w-[75%]" options={[{ value: "in", label: "in" }]} />
    </Space.Compact>
  );
};

export default SettingsForm;
