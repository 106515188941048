import { PrinterOutlined } from "@ant-design/icons";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const InvoicePage = () => {
  let componentRef = useRef();

  return (
    <div>
      {/* page */}
      <div className="flex justify-center">
        <div className="w-[8.27in]  text-right py-4">
          <ReactToPrint
            trigger={() => (
              <div className="">
                <PrinterOutlined className="text-2xl" />
              </div>
            )}
            content={() => componentRef}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="page-a4" ref={(el) => (componentRef = el)}>
          {/*letter header */}
          <div className="text-indigo-900 text-[0.65rem] text-center">
            <div className="text-center">
              <div className="text-medium text-xl">New Construction LLC</div>
              <div className="text-medium">Construction Company</div>
            </div>
          </div>
          <div className="text-indigo-900 text-[0.65rem] text-center pt-4">
            <span>C.R.&nbsp;:&nbsp;1233305,&nbsp;</span>
            <span>P.O&nbsp;Box&nbsp;:&nbsp;5678,&nbsp;</span>
            <span>P.O&nbsp;Code&nbsp;:&nbsp;342,&nbsp;</span>
            <span>Fax&nbsp;:&nbsp;2132435,&nbsp;</span>
            <span>Tel&nbsp;:&nbsp;22876756,&nbsp;</span>
            <span>Email&nbsp;:&nbsp;info@aljazeera.com,&nbsp;</span>
            <span>F.Code&nbsp;:&nbsp;8756432,&nbsp;</span>
            <div>Al-Ansab,&nbsp;Muscat,&nbsp;Sultanate&nbsp;Of&nbsp;Oman</div>
            <div>VATIN&nbsp;:&nbsp;51234876543754</div>
          </div>
          {/* heading */}
          <hr className="my-4 border-t-indigo-900 border-black" />
          <div className="text-medium text-xl text-center">Invoice</div>
          <div className="text-xs pb-6">
            <div className="flex justify-between">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className="font-semibold text-sm">
                        Envol Engineering LLC
                      </td>
                    </tr>
                    <tr>
                      <td>18th November Street</td>
                    </tr>
                    <tr>
                      <td>Azaiba, Muscat</td>
                    </tr>
                    <tr>
                      <td>Sultanate of Oman</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Invoice No</td>
                      <td className="px-2">:</td>
                      <td className="text-right">#INV-24021901</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td className="px-2">:</td>
                      <td className="text-right"> 20/02/2024</td>
                    </tr>
                    <tr>
                      <td>Due</td>
                      <td className="px-2">:</td>
                      <td className="text-right"> 05/03/2024</td>
                    </tr>
                    <tr>
                      <td>Reference</td>
                      <td className="px-2">:</td>
                      <td className="text-right">Ven Thomas</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td className="px-2">:</td>
                      <td className="text-right">784323456</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="">
            <table className="w-full">
              <thead className="bg-black">
                <tr className="text-sm">
                  <th className="font-normal  text-white  text-left py-2 pl-3">
                    S.No
                  </th>
                  <th className="font-normal text-white text-left">
                    Description
                  </th>
                  <th className="font-normal text-white text-left"></th>
                  <th className="font-normal text-white text-right pr-2">
                    Total
                  </th>
                </tr>
              </thead>
              {/* table body */}
              <tbody className="text-sm">
                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">1</td>
                  <td className="">
                    <div className="">Conference Room Interiors</div>
                  </td>
                  <td className="py-1 text-center"></td>
                  <td className="text-right pr-2">49.000</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">2</td>
                  <td className="">
                    <div className="">Reception Area Funishing</div>
                  </td>
                  <td className="py-1 text-center"></td>
                  <td className="text-right pr-2">55.000</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">3</td>
                  <td className="">
                    <div className="">Pantry Area Painting</div>
                  </td>
                  <td className="py-1 text-center"></td>
                  <td className="text-right pr-2">49.000</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">4</td>
                  <td className="">
                    <div className="">Executive Suite Remodel</div>
                  </td>
                  <td className="py-1 text-center"></td>
                  <td className="text-right pr-2">19.000</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">5</td>
                  <td className="">
                    <div className="">Collaboration Space Interiors</div>
                  </td>
                  <td className="py-1 text-center"></td>
                  <td className="text-right pr-2">89.000</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center">6</td>
                  <td className="">
                    <div className="">IT Room Electicals</div>
                  </td>
                  <td className="py-1 text-center"></td>
                  <td className="text-right pr-2">89.000</td>
                </tr>
              </tbody>
              {/* table foot */}
              <tfoot className="text-sm ">
                <tr className="">
                  <th colSpan={2}></th>
                  <th className="font-normal pt-1 text-right">Total</th>
                  <td className="font-normal text-right pr-2">350.000</td>
                </tr>
                <tr>
                  <th colSpan={2}></th>
                  <th className="font-normal text-right">VAT (5%)</th>
                  <td className="font-normal text-right pr-2">17.500</td>
                </tr>
                <tr>
                  <th className="" colSpan={2}></th>
                  <th className="font-normal text-right text-white bg-black py-1">
                    Grand Total (OMR)
                  </th>
                  <td className="font-normal text-right text-white bg-black pr-2">
                    367.500
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* note */}
          <div className="pt-4 text-sm">
            <div className="">
              <div className="font-semibold">Note&nbsp;&nbsp;</div>
              <li>
                Please feel free to contact us for any questions or concerns.
              </li>
            </div>
            <div className="pt-4">
              <div className="font-semibold">
                Terms & Conditions&nbsp;&nbsp;
              </div>
              <li>Total payment due in 30 days.</li>
            </div>
          </div>
          <div className="">
            <div
              className="page-footer mb-[1.5cm]"
              style={{ padding: " 1.6cm" }}
            >
              <div className="flex justify-between px-[0.5cm]">
                <div className="text-[0.65rem]">
                  Customer's Signature (Name/Designation/Co. Seal)
                </div>
                <div className="text-[0.65rem]">For New Construction LLC</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;
