import React from "react";

const DotIcon = (props) => {
  let height = props?.height ? props?.height : 14;
  let width = props?.width ? props?.width : 14;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 29.107 29.107"
    >
      <g>
        <path
          d="M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default DotIcon;
