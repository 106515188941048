import {
  CalendarOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DotIcon from "../../../Assets/Svg/dot";
import Newtable from "../../Items/table";
import AddButton from "../../../Componets/Buttons/add";
import projects from "../../../Data/projects.json";
import customers from "../../../Data/customers.json";
import areas from "../../../Data/areas.json";
import tasks from "../../../Data/tasks.json";
import timesheet from "../../../Data/timesheet.json";
import issues from "../../../Data/issues.json";

const ViewProject = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [tab, setTab] = useState("Area");
  const [project, setProject] = useState();

  const handleTab = (value) => {
    setTab(value);
  };

  const area_data = [];
  areas?.map((value, index) => {
    area_data?.push({
      Area: (
        <div className="flex">
          <img className="w-[50px] h-[50px] rounded-md" src={value?.image} />
          <div className="text-end my-auto pl-2">{value?.name}</div>
        </div>
      ),
      Cost: value?.cost,
      Progress: (
        <div className="flex">
          <div>
            <Progress
              type="circle"
              percent={value?.progress}
              size={25}
              format={(percent) => ``}
              strokeColor="#10B981"
              strokeWidth={16}
            />
          </div>
          <div className="my-auto px-2">{value?.progress}%</div>
        </div>
      ),
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.status
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-red-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.status ? "Active" : "Inactive"}</span>
        </div>
      ),
      Action: (
        <button>
          <FormOutlined
            className="text-gray-500 text-lg hover:text-[#2D53DA]"
            onClick={() => navigate(`/app/projects/area/add/${value?.id}`)}
          />
        </button>
      ),
    });
  });

  const get_area = (id) => {
    let data;
    areas?.map((value, index) => {
      if (value?.id == id) {
        data = value;
      }
    });

    return data;
  };

  const tasks_data = [];
  tasks?.map((value, index) => {
    tasks_data?.push({
      Name: (
        <div className="flex">
          <img className="w-[50px] h-[50px] rounded-md" src={value?.image} />
          <div className="text-end my-auto pl-2">{value?.name}</div>
        </div>
      ),
      Area: get_area(value?.area)?.name,
      Cost: value?.cost,
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.status == "Active"
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-yellow-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.status}</span>
        </div>
      ),
      Action: (
        <button>
          <FormOutlined
            className="text-gray-500 text-lg hover:text-[#2D53DA]"
            onClick={() =>
              navigate(`/app/projects/area/task/edit/${value?.id}`)
            }
          />
        </button>
      ),
    });
  });

  const timesheet_data = [];
  timesheet?.map((value, index) => {
    timesheet_data?.push({
      Name: value?.date,
      Area: value?.area,
      Task: value?.task,
      Cost: value?.cost,
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.status == "Active"
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-yellow-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.status}</span>
        </div>
      ),
      Action: (
        <button>
          <FormOutlined
            className="text-gray-500 text-lg hover:text-[#2D53DA]"
            onClick={() => navigate(`/app/projects/area/task/timesheet/add`)}
          />
        </button>
      ),
    });
  });

  const issues_data = [];
  issues?.map((value, index) => {
    issues_data?.push({
      Name: value?.name,
      Area: value?.area,
      Task: value?.task,
      Status: (
        <div>
          <span
            role="img"
            aria-label="up-circle"
            className={
              value?.status == "Completed"
                ? "anticon anticon-up-circle text-[#10B981]"
                : "anticon anticon-up-circle text-red-500"
            }
          >
            <DotIcon />
          </span>
          <span className="pl-1">{value?.status}</span>
        </div>
      ),
      Action: (
        <button>
          <FormOutlined
            className="text-gray-500 text-lg hover:text-[#2D53DA]"
            onClick={() => navigate(`/app/projects/area/task/issues`)}
          />
        </button>
      ),
    });
  });

  const data = {
    Area: {
      head: [
        {
          name: "Area",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Cost",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Progress",
          className: "p-3 text-gray-500 text-sm",
          bodyClass: "p-3 text-gray-700 text-sm",
        },
        {
          name: "Status",
          className: "p-3 text-gray-500 text-sm",
          bodyClass: "p-3 text-gray-700 text-sm",
        },
        {
          name: "Action",
          className: "p-3 text-gray-500 text-center",
          bodyClass: "p-3 text-gray-700 text-center",
        },
      ],
      body: area_data,
    },
    Tasks: {
      head: [
        {
          name: "Name",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Area",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Cost",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Status",
          className: "p-3 text-gray-500 text-sm",
          bodyClass: "p-3 text-gray-700 text-sm",
        },
        {
          name: "Action",
          className: "p-3 text-gray-500 text-center",
          bodyClass: "p-3 text-gray-700 text-center",
        },
      ],
      body: tasks_data,
    },
    Timesheet: {
      head: [
        {
          name: "Name",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Area",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Task",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Cost",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Status",
          className: "p-3 text-gray-500 text-sm",
          bodyClass: "p-3 text-gray-700 text-sm",
        },
        {
          name: "Action",
          className: "p-3 text-gray-500 text-center",
          bodyClass: "p-3 text-gray-700 text-center",
        },
      ],
      body: timesheet_data,
    },
    Issues: {
      head: [
        {
          name: "Name",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Area",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Task",
          className: "p-5 text-gray-500 text-sm",
          bodyClass: "p-3 text-black text-sm",
        },
        {
          name: "Status",
          className: "p-3 text-gray-500 text-sm",
          bodyClass: "p-3 text-gray-700 text-sm",
        },
        {
          name: "Action",
          className: "p-3 text-gray-500 text-center",
          bodyClass: "p-3 text-gray-700 text-center",
        },
      ],
      body: issues_data,
    },
  };

  const getCustomer = (id) => {
    let data = {};

    customers?.map((value, index) => {
      if (value?.id == id) {
        data = value;
      }
    });

    return data;
  };

  const fetchdata = async () => {
    projects?.map((value, index) => {
      if (value?.id == id) {
        let customer = getCustomer(value?.customer);
        setProject({ ...value, customer: customer });
      }
    });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <div>
      {/* Header Topbar Start */}

      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects")}
          >
            Projects
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span className="">View</span>
        </div>
        {/* <div>
          <AddButton
            name="Area"
            onClick={() => navigate("/app/projects/area/add")}
          />
        </div> */}
      </div>

      {/* Header Topbar End*/}
      <div className="px-4 pt-4">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between">
            <div>
              <div className="flex">
                <img
                  src={project?.image}
                  className="w-[125px] h-[125px] rounded-md"
                />
                <div className="pl-4 text-sm text-gray-700">
                  <span className="text-base text-medium text-black pr-2">
                    {project?.name}
                  </span>
                  <span className="bg-gray-200 text-sm px-2 py-1 rounded-lg text-gray-500">
                    {project?.pno}
                  </span>
                  <div className="pt-1">
                    <UserOutlined />
                    <span className="pl-2 ">{project?.customer?.name}</span>
                  </div>
                  <div>
                    <PhoneOutlined style={{ transform: "rotate(90deg)" }} />
                    <span className="pl-2">{project?.customer?.phone}</span>
                  </div>
                  <div>
                    <MailOutlined />
                    <span className="pl-2">{project?.customer?.email}</span>
                  </div>
                  <div>
                    <EnvironmentOutlined />
                    <span className="pl-2">
                      {project?.customer?.area +
                        ", " +
                        project?.customer?.city +
                        ", " +
                        project?.customer?.state +
                        ", " +
                        project?.customer?.country}
                    </span>
                  </div>
                  <div>
                    <CalendarOutlined />
                    <span className="px-2">
                      {project?.start}&nbsp;-&nbsp;{project?.start}
                    </span>
                    <span className="px-2 text-xs text-medium bg-green-500 text-white rounded-full">
                      On-Time
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <FormOutlined
                  className="text-gray-500 text-xl hover:text-[#2D53DA]"
                  onClick={() => navigate("/app/projects/add")}
                />
              </div>
            </div>
          </div>
          {/* Progress */}
          <div className="pt-4">
            <Progress
              className="text-medium"
              strokeColor="#10B981"
              percent={project?.progress}
              strokeWidth={6}
            />
          </div>
        </div>
      </div>

      {/* data */}
      <div className="px-4 pt-3">
        <div className="bg-white rounded-md">
          <div className="flex justify-between">
            <div
              className={
                tab == "Area"
                  ? "text-medium text-sm w-full bg-gray-300 p-4 cursor-pointer"
                  : "text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              }
              onClick={() => handleTab("Area")}
            >
              <div className="text-center uppercase">Area (5)</div>
              {/* <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 4
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 1
              </div> */}
            </div>
            <div
              className={
                tab == "Tasks"
                  ? "text-medium text-sm w-full bg-gray-300 p-4 cursor-pointer"
                  : "text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              }
              onClick={() => handleTab("Tasks")}
            >
              <div className="text-center uppercase">Tasks (5)</div>
              {/* <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 3
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 2
              </div> */}
            </div>
            <div
              className={
                tab == "Timesheet"
                  ? "text-medium text-sm w-full bg-gray-300 p-4 cursor-pointer"
                  : "text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              }
              onClick={() => handleTab("Timesheet")}
            >
              <div className="text-center uppercase">Timesheet (4)</div>
              {/* <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 8
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 40
              </div> */}
            </div>
            <div
              className={
                tab == "Issues"
                  ? "text-medium text-sm w-full bg-gray-300 p-4 cursor-pointer"
                  : "text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              }
              onClick={() => handleTab("Issues")}
            >
              <div className="text-center uppercase">Issues (2)</div>
              {/* <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Remaining : 4
              </div>
              <div className="text-center text-xs text-medium pt-2 text-gray-500">
                Completed : 11
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* info */}
      {/* <div className="px-4 pt-3">
        <div className="bg-white rounded-md">
          <div className="p-4 flex justify-between">
            <div className="text-medium text-sm">
              <div className="text-center text-gray-500">Employees</div>
              <div className="text-center text-semi-bold pt-2 text-blue-500">
                45
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Estimated Cost</div>
              <div className="text-center text-semi-bold pt-2 text-green-500">
                1245
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Employees Cost</div>
              <div className="text-center text-semi-bold pt-2 text-rose-300">
                -300
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Inventory</div>
              <div className="text-center text-semi-bold pt-2 text-rose-300">
                -400
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Contractors</div>
              <div className="text-center text-semi-bold pt-2 text-rose-300">
                -250
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center  text-gray-500">Total Expense</div>
              <div className="text-center text-semi-bold pt-2 text-red-500">
                -950
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center text-gray-500">Revenue</div>
              <div className="text-center text-semi-bold pt-2 text-green-500">
                +245
              </div>
            </div>
            <div className="text-medium text-sm">
              <div className="text-center text-gray-500">Status</div>
              <div className="text-center text-semi-bold pt-2 text-cyan-500">
                <span className="pl-1">Active</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* newtable */}
      <div className="p-4">
        <div className="text-right pb-4">
          {/* /app/projects/area/task/add */}

          {/* /app/projects/area/task/view */}
          {/* /app/projects/area/task/edit/:id */}

          {/* /app/projects/area/task/timesheet/add */}
          {/* /app/projects/area/task/timesheet/edit/:id */}

          {/* /app/projects/area/task/issues*/}
          {/* /app/projects/area/edit/issues/:id */}

          <AddButton
            name={tab}
            onClick={() =>
              navigate(
                tab == "Area"
                  ? "/app/projects/area/add"
                  : tab == "Tasks"
                  ? "/app/projects/area/task/add"
                  : tab == "Timesheet"
                  ? "/app/projects/area/task/timesheet/add"
                  : tab == "Issues"
                  ? "/app/projects/area/task/issues"
                  : ""
              )
            }
          />
        </div>
        <Newtable head={data[tab]?.head} body={data[tab]?.body} />
      </div>
    </div>
  );
};

export default ViewProject;
