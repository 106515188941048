const forms = [
  {
    name: "Item",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Item name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Units",
        type: "add",
        path: "/app/items/unit",
        form: {
          name: "units",
          allowClear: true,
          showSearch: true,
          placeholder: "Item Unit",
          api: "get-all-item-units",
          options: null,
          value: null,
          required: false,
        },
      },
    ],
  },
  {
    name: "Categorization",
    options: [
      {
        name: "Category",
        type: "add",
        path: "/app/items/category",
        form: {
          name: "category",
          allowClear: true,
          showSearch: true,
          placeholder: "Item Category",
          api: "get-all-item-categories",
          options: null,
          value: null,
          required: false,
        },
      },
      {
        name: "Brand",
        type: "add",
        path: "/app/items/brand",
        form: {
          name: "brands",
          allowClear: true,
          showSearch: true,
          placeholder: "Item Brand",
          api: "get-all-item-brands",
          options: null,
          value: null,
          required: false,
        },
      },
    ],
  },
  {
    name: "Pricing",
    options: [
      {
        name: "Price",
        type: "input",
        form: {
          type: "number",
          name: "price",
          placeholder: "Item Price",
          value: "",
          required: true,
        },
      },
      {
        name: "Tax",
        type: "select",
        form: {
          name: "tax",
          allowClear: true,
          showSearch: false,
          placeholder: "Item Tax",
          options: [{ value: 5, label: "VAT 5%" }],
          value: null,
          required: false,
        },
      },
    ],
  },
  {
    name: "Stock",
    options: [
      {
        name: "Type",
        type: "select",
        show: { check: true },
        form: {
          name: "is_stock",
          allowClear: false,
          placeholder: "Stockable",
          value: 1,
          options: [
            {
              value: 1,
              label: "Stockable",
            },
            {
              value: 0,
              label: "Non-stockable",
            },
          ],
          required: false,
        },
      },
      {
        name: "Stock",
        type: "input",
        show: { status: true, name: "is_stock" },
        form: {
          type: "number",
          name: "stock",
          placeholder: "Item Stock",
          value: "",
          required: false,
        },
      },
      {
        name: "Notify",
        type: "input",
        show: { status: true, name: "is_stock" },
        form: {
          type: "number",
          name: "notify",
          placeholder: "Item Stock Notification",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
