import React from "react";

const PurchaseIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M20.75 3v19a.75.75 0 0 1-1.173.619l-2.495-1.703-2.359 1.693a.748.748 0 0 1-.931-.045L12 20.997l-1.792 1.567a.748.748 0 0 1-.931.045l-2.359-1.693-2.495 1.703A.75.75 0 0 1 3.25 22V3c0-.966.783-1.75 1.75-1.75h14c.967 0 1.75.784 1.75 1.75zM8 10.75h8a.75.75 0 0 0 0-1.5H8a.75.75 0 0 0 0 1.5zm0-4h8a.75.75 0 0 0 0-1.5H8a.75.75 0 0 0 0 1.5zm0 8h4a.75.75 0 0 0 0-1.5H8a.75.75 0 0 0 0 1.5z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default PurchaseIcon;
