import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const create_item_unit = async (unit) => {
  try {
    const data = {
      name: unit?.name,
      status: unit?.status,
      branch: unit?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-item-unit`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const create_item_category = async (category) => {
  try {
    const data = {
      name: category?.name,
      status: category?.status,
      branch: category?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-item-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const create_item_brand = async (brand) => {
  try {
    const data = {
      name: brand?.name,
      status: brand?.status,
      branch: brand?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-item-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const create_item = async (item) => {
  try {
    let data = new FormData();
    data.append("image", item?.image);
    data.append("name", item?.name);
    data.append("units", item?.units);
    data.append("category", item?.category);
    data.append("brands", item?.brands);
    data.append("price", item?.price);
    data.append("stock", item?.stock);
    data.append("notify", item?.notify);
    data.append("tax", item?.tax);
    data.append("is_stock", item?.is_stock);
    data.append("status", item?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-item`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_item = async (item) => {
  try {
    let data = new FormData();
    data.append("id", item?.id);
    data.append("image", item?.image);
    data.append("name", item?.name);
    data.append("units", item?.units);
    data.append("category", item?.category);
    data.append("brands", item?.brands);
    data.append("price", item?.price);
    data.append("stock", item?.stock);
    data.append("notify", item?.notify);
    data.append("tax", item?.tax);
    data.append("is_stock", item?.is_stock);
    data.append("status", item?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-item`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item = async (item) => {
  try {
    const id = item?.id;

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${APIURL}/get-item/${id}`, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_items = async (item) => {
  try {
    const data = {
      search: item?.search,
      sort: item?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-items`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_item_unit,
  create_item_category,
  create_item_brand,
  create_item,
  update_item,
  get_item,
  get_all_items,
};
