import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "./page";
import AddQuotesForm from "./form";

const AddQuotes = () => {
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span
            className="text-gray-500 cursor-pointer"
            // onClick={() => navigate("/app/quotes")}
            onClick={() =>
              navigate(
                location?.pathname == "/app/sales/add"
                  ? "/app/sales"
                  : "/app/quotes"
              )
            }
          >
            {location?.pathname == "/app/sales/add" ? "Invoices" : "Quotes"}
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span className="">Add</span>
        </div>
      </div>
      {/* Header Topbar End*/}
      <AddQuotesForm />
    </div>
  );
};

export default AddQuotes;
