import { Input, Select } from "antd";
import React, { useState } from "react";
import AddButton from "../../../../Componets/Buttons/add";
import { MinusCircleOutlined } from "@ant-design/icons";

const TimesheetTable = () => {
  const [data, setData] = useState([
    {
      key: "",
      option: null,
      item: null,
      price: null,
      hour: null,
      total: null,
    },
  ]);

  const Options = [
    { value: "Employees", label: "Employees" },
    { value: "Contractors", label: "Contractors" },
    { value: "Inventory", label: "Inventory" },
  ];

  const Employees = [
    { value: "1", label: "Jack Ryan" },
    { value: "2", label: "Grace Marie" },
    { value: "3", label: "Luke Sky" },
    { value: "4", label: "Ella May" },
    { value: "5", label: "Mary Kate" },
  ];

  const Inventory = [
    { value: "6", label: "Cement" },
    { value: "7", label: "Fine Aggregate" },
    { value: "8", label: "Tiles" },
    { value: "9", label: "Glass Panels" },
    { value: "10", label: "Concrete Blocks" },
  ];

  const Contractors = [
    { value: "7", label: "#P0-1001" },
    { value: "8", label: "#P0-1002" },
    { value: "9", label: "#P0-1003" },
    { value: "10", label: "#P0-1004" },
    { value: "11", label: "#P0-1005" },
  ];

  const addField = () => {
    let fields = [...data];
    let field = {
      key: "",
      option: "",
      item: "",
      price: "",
      total: "",
    };
    fields?.push(field);
    setData(fields);
  };

  const selectOption = (value, index) => {
    let fields = [...data];
    fields[index].option = value;
    fields[index].item = null;
    setData(fields);
  };

  const selectItem = (value, index) => {
    let fields = [...data];
    if (fields[index].option == "Contractors") {
      fields[index].item = value;
      fields[index].price = value;
      fields[index].total = value;
    } else {
      fields[index].item = value;
      fields[index].price = value;
    }
    setData(fields);
  };

  const getSalePrice = (value, index) => {
    let fields = [...data];
    fields[index].price = value?.target?.value;
    setData(fields);
  };

  const getPrice = (value, index) => {
    let fields = [...data];
    if (fields[index].option == "Contractors") {
      fields[index].hour = value?.target?.value;
      // fields[index].total = value?.target?.value;
      fields[index].total =
        parseInt(value?.target?.value) * parseInt(fields[index].price);
    } else {
      fields[index].hour = value?.target?.value;
      fields[index].total =
        parseInt(value?.target?.value) * parseInt(fields[index].price);
    }

    setData(fields);
  };

  const getOptions = (value) => {
    if (value == "Employees") {
      return Employees;
    } else if (value == "Inventory") {
      return Inventory;
    } else if (value == "Contractors") {
      return Contractors;
    }
  };

  const removeField = (index) => {
    let fields = [...data];
    fields?.splice(index, 1);
    setData(fields);
  };

  return (
    <div>
      <table className="w-full">
        <tbody>
          {data?.map((value, index) => {
            return (
              <tr>
                <td className="p-2">
                  <Select
                    className="w-full"
                    showSearch={true}
                    placeholder="Options"
                    value={value?.option}
                    options={Options}
                    onChange={(value) => selectOption(value, index)}
                  />
                </td>
                <td className="p-2">
                  <Select
                    className="w-full"
                    showSearch={true}
                    placeholder={value?.option ? value?.option : "Options"}
                    value={value?.item}
                    options={getOptions(value?.option)}
                    onChange={(value) => selectItem(value, index)}
                  />
                </td>
                <td className="p-2">
                  <Input
                    placeholder={value?.option == "Contractors" ? "" : "Price"}
                    value={value?.price}
                    // disabled={value?.option == "Contractors" ? false : true}
                    disabled={true}
                    onChange={(value) => getSalePrice(value, index)}
                  />
                </td>
                <td className="p-2">
                  <Input
                    placeholder={
                      value?.option == "Contractors"
                        ? "Price"
                        : value?.option == "Inventory"
                        ? "Quantity"
                        : "Hours"
                    }
                    value={value?.hour}
                    disabled={value?.option == "Contractors" ? true : false}
                    onChange={(value) => getPrice(value, index)}
                  />
                </td>
                <td className="p-2">
                  <Input
                    placeholder="Total"
                    value={value?.total}
                    disabled={true}
                  />
                </td>
                <td className="p-2 text-red-500">
                  {index > 0 && (
                    <MinusCircleOutlined onClick={() => removeField(index)} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="text-right p-4">
        <AddButton onClick={addField} />
      </div>
      <div className="text-medium text-black">
        <span>Grand Total</span>
        <span>&nbsp;&nbsp;:&nbsp;&nbsp;</span>
        <span className="">
          {data.reduce(
            (acc, currentValue) => parseInt(acc) + parseInt(currentValue.total),
            0
          )}
        </span>
      </div>
    </div>
  );
};

export default TimesheetTable;
