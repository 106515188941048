import React from "react";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import Authorization from "../../Main/authorization";

const Layout = () => {
  let location = useLocation();

  let isAuthenticate = Authorization();
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-hidden ml-64">
        <Topbar />
        <div className="flex-1 overflow-y-auto ">
          <h2 className="text-lg font-semibold mb-9">Main Content</h2>
          <div className="">
            {isAuthenticate && location?.pathname != "/app" ? (
              <Outlet />
            ) : isAuthenticate && location?.pathname == "/app" ? (
              <Navigate to={"/app/dashboard"} />
            ) : (
              <Navigate to={"/"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
