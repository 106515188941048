import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Forms from "../../Componets/Form";
import Header from "../../Componets/Header";
import { toast } from "react-toastify";
import {
  create_item_brand,
  create_item_category,
  create_item_unit,
} from "../../Controllers/Items";
import { create_employee_role } from "../../Controllers/Employees";

const AddForm = () => {
  let navigate = useNavigate();
  let pathname = useLocation();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  let name = pathname?.pathname?.replace("/app/", "");
  let title = capitalizeFirstLetter(name.replace("/", " "));

  console.log(title);

  const header = {
    name: title,
    options: {
      menu: [
        {
          name: "Items",
          path: "/app/items",
        },
        {
          name: "Add",
          path: "/app/items/add",
        },
        {
          name: title,
          path: "",
        },
      ],
    },
  };

  const employee_header = {
    name: title,
    options: {
      menu: [
        {
          name: "Employee",
          path: "/app/employees",
        },
        {
          name: "Add",
          path: "/app/employees/add",
        },
        {
          name: title,
          path: "",
        },
      ],
    },
  };

  const forms = {
    "Items unit": {
      forms: [
        {
          name: "Unit",
          options: [
            {
              name: "Name",
              type: "input",
              form: {
                type: "text",
                name: "name",
                placeholder: "Unit name",
                class: "",
                value: "",
                required: true,
                error: "",
              },
            },
          ],
        },
      ],
      create: "",
    },
    "Items category": {
      forms: [
        {
          name: "Category",
          options: [
            {
              name: "Name",
              type: "input",
              form: {
                type: "text",
                name: "name",
                placeholder: "Category name",
                class: "",
                value: "",
                required: true,
                error: "",
              },
            },
          ],
        },
      ],
      create: "",
    },
    "Items brand": {
      forms: [
        {
          name: "Brand",
          options: [
            {
              name: "Name",
              type: "input",
              form: {
                type: "text",
                name: "name",
                placeholder: "Brand name",
                class: "",
                value: "",
                required: true,
                error: "",
              },
            },
          ],
        },
      ],
    },
    "Employees role": {
      forms: [
        {
          name: "Role",
          options: [
            {
              name: "Name",
              type: "input",
              form: {
                type: "text",
                name: "name",
                placeholder: "Role name",
                class: "",
                value: "",
                required: true,
                error: "",
              },
            },
          ],
        },
      ],
    },
  };

  const formdata = async (data) => {
    console.log(data, "datadata");

    if (data?.status) {
      if (title == "Items unit") {
        const unit = await create_item_unit(data?.data);
        if (unit?.status) {
          toast?.success(unit?.message);
          navigate(-1);
        } else {
          toast?.error(unit?.message);
        }
      } else if (title == "Items category") {
        const unit = await create_item_category(data?.data);
        if (unit?.status) {
          toast?.success(unit?.message);
          navigate(-1);
        } else {
          toast?.error(unit?.message);
        }
      } else if (title == "Items brand") {
        const unit = await create_item_brand(data?.data);
        if (unit?.status) {
          toast?.success(unit?.message);
          navigate(-1);
        } else {
          toast?.error(unit?.message);
        }
      } else if (title == "Employees role") {
        const unit = await create_employee_role(data?.data);
        if (unit?.status) {
          toast?.success(unit?.message);
          navigate(-1);
        } else {
          toast?.error(unit?.message);
        }
      }
    }
  };

  return (
    <Header header={title == "Employees role" ? employee_header : header}>
      <Forms form={forms?.[title]?.forms} formdata={formdata} />
    </Header>
  );
};

export default AddForm;
