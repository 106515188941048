const headers = {
  name: "Items",
  search: true,
  upload: false,
  download: false,
  button: "/app/items/add",
  options: {
    sort: [
      { value: "1", label: "Name (A-Z)" },
      { value: "2", label: "Name (Z-A)" },
      { value: "3", label: "Price (Acs)" },
      { value: "4", label: "Price (Decs)" },
      { value: "5", label: "Stock (Acs)" },
      { value: "6", label: "Stock (Decs)" },
    ],
    sorts: [
      {
        name: "Category",
        options: [],
      },
    ],
    total: [
      { value: "10", label: "Total" },
      { value: "3", label: "Active" },
      { value: "1", label: "Inactive" },
      { value: "1", label: "Low" },
      { value: "1", label: "Out of stock" },
    ],
  },
};

export default headers;
