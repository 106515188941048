import React, { useState } from "react";
import AddButton from "../Buttons/add";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Checkbox, Dropdown, Input, Select } from "antd";
import {
  DownOutlined,
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
  SwapOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Container from "../Container";

const Header = (props) => {
  let navigate = useNavigate();

  const formdata = props?.formdata;

  const [open, setOpen] = useState(false);

  const name = props?.header?.name;
  const search = props?.header?.search;
  const upload = props?.header?.upload;
  const download = props?.header?.download;
  const button = props?.header?.button;
  const sortOptions = props?.header?.options?.sort;
  const filterOptions = props?.header?.options?.fillter;
  const menuOptions = props?.header?.options?.menu;
  const total = props?.header?.options?.total;

  const items = [];
  filterOptions?.map((value, index) => {
    items?.push({
      key: { index },
      label: (
        <div>
          <div className="felx justify-between">
            <div className="text-gray-500 py-2">{value?.name}</div>
          </div>
          <div className="flex flex-wrap">
            {value?.options?.map((val, ind) => {
              return (
                <div className="pb-1 w-[20%]">
                  <div className="flex">
                    <div>
                      <Checkbox />
                    </div>
                    <div className="pl-2">{val?.label}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ),
    });
  });

  const menu = [];
  menuOptions?.map((value, index) => {
    menu?.push({
      title: value?.name,
      onClick: () => navigate(value?.path),
      className: value?.path ? "cursor-pointer hover:text-[#2d53da]" : "",
    });
  });

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const redirect = () => {
    if (button) {
      navigate(button);
    }
  };

  const handleSearch = (e) => {
    let value = e?.target?.value;
    formdata({ search: value });
  };

  const handleChange = (e) => {
    let value = e;
    formdata({ sort: value });
  };

  return (
    <div>
      <div className="fixed top-16 left-64 right-0 z-20">
        <div className="p-4 flex justify-between items-center border-b bg-white h-[60px]">
          {/* name */}
          <div>{name}</div>
          <div>
            <div className="flex">
              {/* search */}
              {search && (
                <div className="pr-4">
                  <Input
                    allowClear
                    className="search"
                    placeholder=" Search"
                    prefix={
                      <span className="text-gray-500 text-medium">
                        <SearchOutlined />
                      </span>
                    }
                    onChange={handleSearch}
                  />
                </div>
              )}
              {/* sort */}
              {sortOptions?.length > 0 && (
                <div className="pr-4">
                  <Select
                    allowClear
                    className="w-32"
                    placeholder={
                      <span>
                        <SwapOutlined style={{ transform: "rotate(90deg)" }} />
                        <span className="pl-2">Sort</span>
                      </span>
                    }
                    options={sortOptions}
                    onChange={handleChange}
                  />
                </div>
              )}
              {/* filtter */}
              {filterOptions?.length > 0 && (
                <div className="pr-4">
                  <Dropdown
                    className="w-full"
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottom"
                    onOpenChange={handleOpenChange}
                    open={open}
                  >
                    <Button className="text-[#BFBFBF]">
                      <div className="flex">
                        <div>
                          <FilterOutlined />
                        </div>
                        <div className="pl-2">Filter</div>
                        <div className="pl-4 text-xs pt-1">
                          <DownOutlined />
                        </div>
                      </div>
                    </Button>
                  </Dropdown>
                </div>
              )}
              {/* import */}
              {download && (
                <div className="pr-4">
                  <Button className="text-[#BFBFBF]">
                    <div className="flex">
                      <div className="">Export</div>
                      <div className="pl-4 pt-0.5">
                        <UploadOutlined />
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {/* export */}
              {upload && (
                <div className="pr-4">
                  <Button className="text-[#BFBFBF]">
                    <div className="flex">
                      <div className="">Import</div>
                      <div className="pl-4 pt-0.5">
                        <DownloadOutlined />
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {/* add */}
              {button && (
                <div>
                  <AddButton onClick={redirect} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            menuOptions?.length > 0
              ? "p-4 bg-white border-b h-[40px] flex justify-between items-center text-xs text-medium text-gray-400 uppercase"
              : "p-4 bg-white border-b h-[40px] flex justify-end items-center text-xs text-medium text-gray-400 uppercase"
          }
        >
          {menuOptions?.length > 0 && (
            <div>
              <Breadcrumb className="text-regular" separator=">" items={menu} />
            </div>
          )}
          {total?.map((value, index) => {
            return (
              <div
                className={
                  index == total?.length - 1
                    ? "cursor-pointer"
                    : "pr-3 cursor-pointer"
                }
              >
                {value?.label} ({value?.value})
              </div>
            );
          })}
        </div>
      </div>
      <Container>{props?.children}</Container>
    </div>
  );
};

export default Header;
