import {
  DownloadOutlined,
  FileOutlined,
  FilePdfOutlined,
  RightOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Newtable from "../../Items/table";
import ReportTable from "./table";
import { DatePicker } from "antd";

const ViewReports = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const path = location?.pathname?.replace("/app/reports/", "");
  const name = path.charAt(0).toUpperCase() + path.slice(1);

  const { RangePicker } = DatePicker;

  const Option = () => {
    return (
      <div className="flex justify-end text-bold ">
        <div className="bg-blue-100 hover:bg-blue-500 hover:text-white text-lg  px-3 py-1 rounded-md text-blue-700 cursor-pointer">
          <ShareAltOutlined className="text-medium" />
        </div>
        <div className="bg-blue-100 text-lg hover:bg-blue-500 hover:text-white px-3 py-1 rounded-md text-blue-700 cursor-pointer mx-3">
          <DownloadOutlined />
        </div>
        <div className="bg-blue-100 text-lg  hover:bg-blue-500 hover:text-white px-3 py-1 rounded-md text-blue-700 cursor-pointer">
          <FilePdfOutlined />
        </div>
      </div>
    );
  };

  //projects
  const ProjectHeader = [
    { value: "Project", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    // { value: "", className: "p-3 text-medium text-black" },
    // { value: "", className: "p-3 text-medium text-black" },
    { value: "", className: "p-3 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const ProjectsHead = [
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r border-t",
    },
    {
      name: "Employees",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-gray-700 border-r border-t",
    },
    {
      name: "Inventory",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-gray-700 border-r border-t",
    },
    {
      name: "Contractors",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-gray-700 border-r border-t",
    },
    // {
    //   name: "Expenses",
    //   className: "p-3 text-gray-500  border-r border-t",
    //   bodyClass: "p-3 text-gray-700  border-r border-t",
    // },
    // {
    //   name: "Revenue",
    //   className: "p-3 text-gray-500 border-r border-t",
    //   bodyClass: "p-3 text-gray-700 border-r border-t",
    // },
    {
      name: "Total",
      className: "p-3 text-gray-500  text-center border-t",
      bodyClass: "p-3 text-gray-700 text-center border-t",
    },
  ];
  const ProjectBody = [
    {
      Name: "Envol Office Remodel",
      Total: "1450.754",
      Employees: "245",
      Inventory: "135",
      Contractors: "128",
      // Expenses: "1785",
      Total: "518",
    },
    {
      Name: "Nijum's House Remodel",
      Total: "2410.875",
      Employees: "345",
      Inventory: "175",
      Contractors: "350",
      // Expenses: "1015",
      // Revenue: "870",
      Total: "870",
    },
    {
      Name: "Brown's House Gardening",
      Total: "3570.575",
      Employees: "245",
      Inventory: "235",
      Contractors: "128",
      // Expenses: "1785",
      // Revenue: "735",
      Total: "598",
    },
    {
      Name: "Richard House Construction",
      Total: "5678.985",
      Employees: "675",
      Inventory: "200",
      Contractors: "235",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "1110",
    },
    {
      Name: "Daniel Matthew House Interiors",
      Total: "6789.235",
      Employees: "452",
      Inventory: "180",
      Contractors: "215",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "847",
    },
    {
      Name: "Home Town Building",
      Total: "4567.785",
      Employees: "254",
      Inventory: "210",
      Contractors: "200",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "388",
    },
    {
      Name: "Offshore House Construction",
      Total: "1245.754",
      Employees: "125",
      Inventory: "140",
      Contractors: "123",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "383",
    },
    {
      Name: "Henry House Landscape",
      Total: "2345.345",
      Employees: "756",
      Inventory: "155",
      Contractors: "128",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "1131",
    },
    {
      Name: "James Karl Office Remodel",
      Total: "4567.785",
      Employees: "456",
      Inventory: "187",
      Contractors: "185",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "810",
    },
    {
      Name: "Lyod Warehouse Interiors",
      Total: "7856.225",
      Employees: "875",
      Inventory: "379",
      Contractors: "415",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "1644",
    },
    {
      Name: "Martin Office Repaint",
      Total: "7854.275",
      Employees: "556",
      Inventory: "456",
      Contractors: "618",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "1612",
    },
    {
      Name: "David Lawn Cleaning",
      Total: "8755.355",
      Employees: "456",
      Inventory: "500",
      Contractors: "455",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "1411",
    },
    {
      Name: "Green Ltd Headquaters Construction",
      Total: "9754.222",
      Employees: "755",
      Inventory: "95",
      Contractors: "258",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "1158",
    },
    {
      Name: "NYC Park Cleaning",
      Total: "8754.345",
      Employees: "452",
      Inventory: "75",
      Contractors: "355",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "882",
    },
    {
      Name: "Lane Hospital Interiors",
      Total: "9756.245",
      Employees: "125",
      Inventory: "155",
      Contractors: "255",
      // Expenses: "1015",
      // Revenue: "435",
      Total: "525",
    },
  ];
  const ProjectFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black border-r" },
    { value: "6150", className: "p-3 text-semi-bold text-black border-r" },
    { value: "3172", className: "p-3 text-semi-bold text-black border-r" },
    // { value: "3450", className: "p-3 text-semi-bold text-black border-r" },
    // { value: "1456", className: "p-3 text-semi-bold text-black border-r" },
    { value: "3565", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "12887",
      className: "p-3 text-semi-bold text-black text-center",
    },
  ];

  //inventory
  const InventoryHeader = [
    { value: "Inventory", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const InventoryHead = [
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Price",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-gray-700 border-r",
    },
    {
      name: "Stock",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-gray-700 border-r",
    },
    {
      name: "Total",
      className: "p-3 text-gray-500 text-center border-t",
      bodyClass: "p-3 text-gray-700 text-center",
    },
  ];
  const InventoryBody = [
    {
      Name: "Cement",
      Stock: "50",
      Price: "5",
      Total: "250",
    },
    {
      Name: "Fine Aggregate",
      Stock: "10",
      Price: "10",
      Total: "100",
    },
    {
      Name: "Tiles",
      Stock: "4",
      Price: "10",
      Total: "40",
    },
    {
      Name: "Glass Panels",
      Stock: "50",
      Price: "15",
      Total: "750",
    },
    {
      Name: "Reinforcement Steel",
      Stock: "34",
      Price: "100",
      Total: "3400",
    },
    {
      Name: "Concrete Blocks",
      Stock: "11",
      Price: "15",
      Total: "165",
    },
    {
      Name: "Marbles",
      Stock: "0",
      Price: "10",
      Total: "0",
    },
    {
      Name: "Binding Wire",
      Stock: "5",
      Price: "5",
      Total: "25",
    },
    {
      Name: "Paint",
      Stock: "155",
      Price: "3",
      Total: "465",
    },
    {
      Name: "Plywood Sheet",
      Stock: "10",
      Price: "25",
      Total: "250",
    },
    {
      Name: "Binding Wire",
      Stock: "2",
      Price: "48",
      Total: "96",
    },
    {
      Name: "Concrete Blocks",
      Stock: "33",
      Price: "5",
      Total: "165",
    },
    {
      Name: "Glass Panels",
      Stock: "35",
      Price: "15",
      Total: "525",
    },
    {
      Name: "Reinforcement Steel",
      Stock: "104",
      Price: "10",
      Total: "1000",
    },
    {
      Name: "Fine Aggregate",
      Stock: "24",
      Price: "10",
      Total: "240",
    },
    {
      Name: "Marbles",
      Stock: "50",
      Price: "15",
      Total: "750",
    },
  ];
  const InventoryFoot = [
    { value: "Total", className: "p-3  text-semi-bold text-black border-r" },
    { value: "301", className: "p-3  text-semi-bold text-black border-r" },
    { value: "577", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "8221",
      className: "p-3 text-semi-bold text-black text-center border-r",
    },
  ];

  //employees
  const EmployeesHeader = [
    { value: "Employees", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const EmployeesHead = [
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r  border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Paid",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Hours",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3  text-center text-gray-500 border-r border-t",
      bodyClass: "p-3 text-center text-black border-r",
    },
  ];
  const EmployeesBody = [
    { Name: "Jack Ryan", Paid: "15", Hours: "32", Total: "480" },
    { Name: "Grace Marie", Paid: "10", Hours: "12", Total: "120" },
    { Name: "Luke Sky", Paid: "7", Hours: "26", Total: "182" },
    { Name: "Ella May", Paid: "8", Hours: "15", Total: "120" },
    { Name: "Mary Kate", Paid: "4", Hours: "20", Total: "80" },
    { Name: "Alex James", Paid: "10", Hours: "25", Total: "250" },
    { Name: "Sarah Jane", Paid: "13", Hours: "15", Total: "195" },
    { Name: "Isabella", Paid: "15", Hours: "25", Total: "375" },
    { Name: "Jacob", Paid: "20", Hours: "10", Total: "200" },
    { Name: "Mia Rose", Paid: "25", Hours: "15", Total: "375" },
    { Name: "Sophie Anne", Paid: "20", Hours: "20", Total: "400" },
    { Name: "Nova Dawn", Paid: "12", Hours: "25", Total: "300" },
    { Name: "Ocean Breeze", Paid: "15", Hours: "30", Total: "450" },
    { Name: "Phoenix Blaze", Paid: "20", Hours: "5", Total: "100" },
  ];

  const EBody = [
    { Name: "Jack Ryan", Paid: "15", Hours: "3", Total: "45" },
    { Name: "Grace Marie", Paid: "10", Hours: "4", Total: "40" },
    { Name: "Luke Sky", Paid: "7", Hours: "5", Total: "35" },
    { Name: "Ella May", Paid: "8", Hours: "8", Total: "64" },
    { Name: "Mary Kate", Paid: "4", Hours: "6", Total: "24" },
    { Name: "Alex James", Paid: "10", Hours: "12", Total: "120" },
    { Name: "Sarah Jane", Paid: "13", Hours: "15", Total: "195" },
    { Name: "Isabella", Paid: "15", Hours: "8", Total: "120" },
    { Name: "Jacob", Paid: "20", Hours: "5", Total: "100" },
    { Name: "Mia Rose", Paid: "25", Hours: "4", Total: "100" },
    { Name: "Sophie Anne", Paid: "20", Hours: "0", Total: "0" },
    { Name: "Nova Dawn", Paid: "12", Hours: "8", Total: "96" },
    { Name: "Ocean Breeze", Paid: "15", Hours: "2", Total: "30" },
    { Name: "Phoenix Blaze", Paid: "20", Hours: "5", Total: "100" },
  ];

  const EFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black border-r" },
    { value: "194", className: "p-3 text-semi-bold text-black border-r" },
    { value: "85", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "2760",
      className: "p-3  text-semi-bold text-center text-black border-r",
    },
  ];

  const EmployeesFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black border-r" },
    { value: "194", className: "p-3 text-semi-bold text-black border-r" },
    { value: "275", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "3627",
      className: "p-3  text-semi-bold text-center text-black border-r",
    },
  ];

  //customers
  const CustomersHeader = [
    { value: "Customers", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const CustomersHead = [
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r ",
    },
    {
      name: "Received",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Receivable",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3  text-center text-gray-500 border-t",
      bodyClass: "p-3 text-center text-black ",
    },
  ];
  const CustomersBody = [
    {
      Name: "StellarCraft Technologies",
      Received: "115",
      Receivable: "200",
      Total: "315",
    },
    {
      Name: "QuantumScape Innovations",
      Received: "235",
      Receivable: "100",
      Total: "335",
    },
    {
      Name: "BrightWave Solutions",
      Received: "515",
      Receivable: "0",
      Total: "515",
    },
    {
      Name: "NovaGenix Labs",
      Received: "1250",
      Receivable: "300",
      Total: "1150",
    },
    {
      Name: "InfinityWorks Inc.",
      Received: "2450",
      Receivable: "200",
      Total: "2250",
    },
    {
      Name: "ZenithTech Corporation",
      Received: "500",
      Receivable: "200",
      Total: "700",
    },
    {
      Name: "TitanForge Industries",
      Received: "250",
      Receivable: "200",
      Total: "450",
    },
    {
      Name: "HorizonSync Enterprises",
      Received: "255",
      Receivable: "275",
      Total: "530",
    },
    {
      Name: "NexusGen Biotech",
      Received: "200",
      Receivable: "100",
      Total: "300",
    },
    {
      Name: "FusionWorks Dynamics",
      Received: "350",
      Receivable: "300",
      Total: "650",
    },
    {
      Name: "PulseTech Innovations",
      Received: "215",
      Receivable: "315",
      Total: "330",
    },
    {
      Name: "LuminaSphere Solutions",
      Received: "145",
      Receivable: "115",
      Total: "260",
    },
    {
      Name: "VertexSynergy Technologies",
      Received: "540",
      Receivable: "0",
      Total: "540",
    },
    {
      Name: "CelestialTech Solutions",
      Received: "250",
      Receivable: "1250",
      Total: "1500",
    },
    {
      Name: "AetherDyne Dynamics",
      Received: "200",
      Receivable: "100",
      Total: "300",
    },
  ];
  const CustomersFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black border-r" },
    { value: "7470", className: "p-3 text-semi-bold  text-black border-r" },
    { value: "3655", className: "p-3 text-semi-bold  text-black border-r" },
    {
      value: "10125",
      className: "p-3 text-semi-bold text-center text-black border-r",
    },
  ];

  //suppliers
  const SuppliersHeader = [
    { value: "Suppliers", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const SuppliersHead = [
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Paid",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Payable",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3  text-center text-gray-500 border-t",
      bodyClass: "p-3 text-center text-black",
    },
  ];
  const SuppliersBody = [
    {
      Name: "PulseTech Innovations",
      Paid: "515",
      Payable: "200",
      Total: "535",
    },
    {
      Name: "LuminaSphere Solutions",
      Paid: "175",
      Payable: "0",
      Total: "175",
    },
    {
      Name: "VertexSynergy Technologies",
      Paid: "225",
      Payable: "200",
      Total: "425",
    },
    {
      Name: "VertexSynergy Technologies",
      Paid: "100",
      Payable: "150",
      Total: "250",
    },
    {
      Name: "AetherDyne Dynamics",
      Paid: "1255",
      Payable: "250",
      Total: "1505",
    },
    { Name: "BrightWave Solutions", Paid: "200", Payable: "335", Total: "535" },
    { Name: "NovaGenix Labs", Paid: "150", Payable: "345", Total: "495" },
    {
      Name: "StellarCraft Technologies",
      Paid: "200",
      Payable: "580",
      Total: "780",
    },
    { Name: "InfinityWorks Inc.", Paid: "215", Payable: "315", Total: "330" },
    {
      Name: "ZenithTech Corporation",
      Paid: "115",
      Payable: "208",
      Total: "323",
    },
    {
      Name: "TitanForge Industries",
      Paid: "687",
      Payable: "315",
      Total: "1002",
    },
    {
      Name: "HorizonSync Enterprises",
      Paid: "747",
      Payable: "0",
      Total: "747",
    },
    { Name: "NexusGen Biotech", Paid: "889", Payable: "344", Total: "1233" },
    { Name: "FusionWorks Dynamics", Paid: "250", Payable: "100", Total: "350" },
    {
      Name: "QuantumScape Innovations",
      Paid: "200",
      Payable: "700",
      Total: "900",
    },
  ];
  const SuppliersFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black border-r" },
    { value: "5698", className: "p-3 text-semi-bold text-black border-r" },
    { value: "4042", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "9160",
      className: "p-3 text-center text-semi-bold  text-black",
    },
  ];

  //Quotes
  const QuotesHeader = [
    { value: "Quotes", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const QuotesHead = [
    {
      name: "Number",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Received",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Refused",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3  text-center text-gray-500 border-t",
      bodyClass: "p-3 text-center text-black border-r",
    },
  ];
  const QuotesBody = [
    {
      Number: "#QO-1001",
      Name: "StellarCraft Technologies",
      Received: "250",
      Refused: "-",
      Total: "250",
    },
    {
      Number: "#QO-1002",
      Name: "QuantumScape Innovations",
      Received: "-",
      Refused: "2350",
      Total: "2350",
    },
    {
      Number: "#QO-1003",
      Name: "BrightWave Solutions",
      Received: "1250",
      Refused: "-",
      Total: "1250",
    },
    {
      Number: "#QO-1004",
      Name: "NovaGenix Labs",
      Received: "2750",
      Refused: "-",
      Total: "2750",
    },
    {
      Number: "#QO-1005",
      Name: "InfinityWorks Inc.",
      Received: "400",
      Refused: "-",
      Total: "400",
    },
    {
      Number: "#QO-1006",
      Name: "ZenithTech Corporation",
      Received: "-",
      Refused: "225",
      Total: "225",
    },
    {
      Number: "#QO-1007",
      Name: "TitanForge Industries",
      Received: "-",
      Refused: "457",
      Total: "457",
    },
    {
      Number: "#QO-1008",
      Name: "HorizonSync Enterprises",
      Received: "-",
      Refused: "1459",
      Total: "1459",
    },
    {
      Number: "#QO-1008",
      Name: "NexusGen Biotech",
      Received: "300",
      Refused: "-",
      Total: "350",
    },
    {
      Number: "#QO-10010",
      Name: "FusionWorks Dynamics",
      Received: "400",
      Refused: "-",
      Total: "450",
    },
    {
      Number: "#QO-10011",
      Name: "PulseTech Innovations",
      Received: "-",
      Refused: "515",
      Total: "515",
    },
    {
      Number: "#QO-10012",
      Name: "LuminaSphere Solutions",
      Received: "790",
      Refused: "-",
      Total: "790",
    },
    {
      Number: "#QO-10013",
      Name: "VertexSynergy Technologies",
      Received: "-",
      Refused: "946",
      Total: "946",
    },
    {
      Number: "#QO-10014",
      Name: "CelestialTech Solutions",
      Received: "1550",
      Refused: "-",
      Total: "1550",
    },
    {
      Number: "#QO-10015",
      Name: "AetherDyne Dynamics",
      Received: "290",
      Refused: "-",
      Total: "290",
    },
  ];
  const QuotesFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black " },
    { value: "", className: "p-3 text-semi-bold text-black border-r" },
    { value: "7980", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "5952",
      className: "p-3 text-semi-bold text-black border-r",
    },
    { value: "14027", className: "p-3 text-semi-bold text-center text-black" },
  ];

  //sales
  const SalesHeader = [
    { value: "Invoice", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const SalesHead = [
    {
      name: "Number",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Received",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Receivable",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3 text-gray-500 text-center border-r border-t",
      bodyClass: "p-3 text-black text-center border-r",
    },
  ];
  const SalesBody = [
    {
      Number: "#INV-1001",
      Name: "StellarCraft Technologies",
      Received: "345",
      Receivable: "115",
      Total: "460",
    },
    {
      Number: "#INV-1002",
      Name: "QuantumScape Innovations",
      Received: "457",
      Receivable: "345",
      Total: "802",
    },
    {
      Number: "#INV-1003",
      Name: "BrightWave Solutions",
      Received: "850",
      Receivable: "216",
      Total: "1066",
    },
    {
      Number: "#INV-1004",
      Name: "NovaGenix Labs",
      Received: "200",
      Receivable: "200",
      Total: "400",
    },
    {
      Number: "#INV-1005",
      Name: "InfinityWorks Inc.",
      Received: "215",
      Receivable: "400",
      Total: "615",
    },
    {
      Number: "#INV-1006",
      Name: "ZenithTech Corporation",
      Received: "678",
      Receivable: "144",
      Total: "822",
    },
    {
      Number: "#INV-1007",
      Name: "TitanForge Industries",
      Received: "515",
      Receivable: "320",
      Total: "835",
    },
    {
      Number: "#INV-1008",
      Name: "HorizonSync Enterprises",
      Received: "1250",
      Receivable: "0",
      Total: "1250",
    },
    {
      Number: "#INV-1009",
      Name: "NexusGen Biotech",
      Received: "700",
      Receivable: "230",
      Total: "930",
    },
    {
      Number: "#INV-10010",
      Name: "FusionWorks Dynamics",
      Received: "0",
      Receivable: "1450",
      Total: "1450",
    },
    {
      Number: "#INV-10011",
      Name: "PulseTech Innovations",
      Received: "456",
      Receivable: "50",
      Total: "506",
    },
    {
      Number: "#INV-10012",
      Name: "LuminaSphere Solutions",
      Received: "322",
      Receivable: "60",
      Total: "382",
    },
    {
      Number: "#INV-10013",
      Name: "VertexSynergy Technologies",
      Received: "500",
      Receivable: "100",
      Total: "600",
    },
    {
      Number: "#INV-10014",
      Name: "CelestialTech Solutions",
      Received: "100",
      Receivable: "975",
      Total: "1075",
    },
    {
      Number: "#INV-10015",
      Name: "AetherDyne Dynamics",
      Received: "570",
      Receivable: "520",
      Total: "1090",
    },
  ];
  const SalesFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black " },
    { value: "", className: "p-3 text-semi-bold text-black border-r " },
    { value: "7158", className: "p-3 text-semi-bold text-black border-r" },
    { value: "5125", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "12283",
      className: "p-3 text-semi-bold text-center text-black border-r",
    },
  ];

  //purchase
  const PurchaseHeader = [
    { value: "Purchase", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const PurchaseHead = [
    {
      name: "Number",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Paid",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Payable",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3 text-gray-500 text-center  border-t",
      bodyClass: "p-3 text-black text-center",
    },
  ];
  const PurchaseBody = [
    {
      Number: "#PO-1001",
      Name: "StellarCraft Technologies",
      Paid: "300",
      Payable: "250",
      Total: "550",
    },
    {
      Number: "#PO-1002",
      Name: "QuantumScape Innovations",
      Paid: "400",
      Payable: "300",
      Total: "700",
    },
    {
      Number: "#PO-1003",
      Name: "BrightWave Solutions",
      Paid: "350",
      Payable: "250",
      Total: "600",
    },
    {
      Number: "#PO-1004",
      Name: "NovaGenix Labs",
      Paid: "90",
      Payable: "90",
      Total: "180",
    },
    {
      Number: "#PO-1005",
      Name: "InfinityWorks Inc.",
      Paid: "1350",
      Payable: "100",
      Total: "1450",
    },
    {
      Number: "#PO-1006",
      Name: "ZenithTech Corporation",
      Paid: "0",
      Payable: "2450",
      Total: "2450",
    },
    {
      Number: "#PO-1007",
      Name: "TitanForge Industries",
      Paid: "950",
      Payable: "910",
      Total: "1860",
    },
    {
      Number: "#PO-1008",
      Name: "HorizonSync Enterprises",
      Paid: "1150",
      Payable: "100",
      Total: "1250",
    },
    {
      Number: "#PO-1009",
      Name: "NexusGen Biotech",
      Paid: "450",
      Payable: "250",
      Total: "700",
    },
    {
      Number: "#PO-10010",
      Name: "FusionWorks Dynamics",
      Paid: "350",
      Payable: "150",
      Total: "500",
    },
    {
      Number: "#PO-10011",
      Name: "PulseTech Innovations",
      Paid: "175",
      Payable: "50",
      Total: "225",
    },
    {
      Number: "#PO-10012",
      Name: "LuminaSphere Solutions",
      Paid: "100",
      Payable: "0",
      Total: "100",
    },
    {
      Number: "#PO-10013",
      Name: "VertexSynergy Technologies",
      Paid: "70",
      Payable: "0",
      Total: "70",
    },
    {
      Number: "#PO-10014",
      Name: "CelestialTech Solutions",
      Paid: "450",
      Payable: "250",
      Total: "700",
    },
    {
      Number: "#PO-10015",
      Name: "AetherDyne Dynamics",
      Paid: "1235",
      Payable: "200",
      Total: "1435",
    },
  ];
  const PurchaseFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black " },
    { value: "", className: "p-3 text-semi-bold text-black border-r" },
    { value: "7420", className: "p-3 text-semi-bold text-black border-r" },
    { value: "5450", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "12770",
      className: "p-3 text-semi-bold text-center text-black border-r",
    },
  ];

  //contractors
  const ContractorsHeader = [
    { value: "Contractors", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    { value: "", className: "p-4 text-medium text-black" },
    {
      value: <Option />,
      className: "p-3 text-medium text-black",
    },
  ];
  const ContractorsHead = [
    {
      name: "Name",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Paid",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Payable",
      className: "p-3 text-gray-500 border-r border-t",
      bodyClass: "p-3 text-black border-r",
    },
    {
      name: "Total",
      className: "p-3 text-gray-500 text-center  border-t",
      bodyClass: "p-3 text-black text-center",
    },
  ];
  const ContractorsBody = [
    {
      Name: "StellarCraft Technologies",
      Paid: "345",
      Payable: "350",
      Total: "695",
    },
    {
      Name: "QuantumScape Innovations",
      Paid: "1190",
      Payable: "100",
      Total: "1290",
    },
    { Name: "BrightWave Solutions", Paid: "459", Payable: "0", Total: "459" },
    { Name: "NovaGenix Labs", Paid: "245", Payable: "100", Total: "345" },
    { Name: "InfinityWorks Inc.", Paid: "540", Payable: "120", Total: "660" },
    {
      Name: "ZenithTech Corporation",
      Paid: "200",
      Payable: "0",
      Total: "200",
    },
    {
      Name: "TitanForge Industries",
      Paid: "560",
      Payable: "160",
      Total: "720",
    },
    {
      Name: "HorizonSync Enterprises",
      Paid: "100",
      Payable: "654",
      Total: "754",
    },
    { Name: "NexusGen Biotech", Paid: "235", Payable: "789", Total: "1024" },
    {
      Name: "FusionWorks Dynamics",
      Paid: "800",
      Payable: "650",
      Total: "1450",
    },
    {
      Name: "PulseTech Innovations",
      Paid: "450",
      Payable: "333",
      Total: "783",
    },
    {
      Name: "LuminaSphere Solutions",
      Paid: "215",
      Payable: "115",
      Total: "330",
    },
    {
      Name: "VertexSynergy Technologies",
      Paid: "560",
      Payable: "720",
      Total: "1280",
    },
    {
      Name: "CelestialTech Solutions",
      Paid: "200",
      Payable: "567",
      Total: "767",
    },
    { Name: "AetherDyne Dynamics", Paid: "875", Payable: "233", Total: "1108" },
  ];
  const ContractorsFoot = [
    { value: "Total", className: "p-3 text-semi-bold text-black " },
    { value: "6974", className: "p-3 text-semi-bold text-black border-r " },
    { value: "4891", className: "p-3 text-semi-bold text-black border-r" },
    {
      value: "11865",
      className: "p-3 text-semi-bold text-center text-black border-r",
    },
  ];

  const [status, setStatus] = useState(false);

  const handleData = () => {
    if (location?.pathname == "/app/reports/employees") {
      setStatus(!status);
    }
  };

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/reports")}
          >
            Reports
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span>{name}</span>
        </div>
        <div>
          <RangePicker onChange={handleData} />
        </div>
      </div>

      <div className="p-4">
        <ReportTable
          header={
            location?.pathname == "/app/reports/projects"
              ? ProjectHeader
              : location?.pathname == "/app/reports/inventory"
              ? InventoryHeader
              : location?.pathname == "/app/reports/employees"
              ? EmployeesHeader
              : location?.pathname == "/app/reports/customers"
              ? CustomersHeader
              : location?.pathname == "/app/reports/suppliers"
              ? SuppliersHeader
              : location?.pathname == "/app/reports/quotes"
              ? QuotesHeader
              : location?.pathname == "/app/reports/sales"
              ? SalesHeader
              : location?.pathname == "/app/reports/purchases"
              ? PurchaseHeader
              : location?.pathname == "/app/reports/contractors"
              ? ContractorsHeader
              : ""
          }
          head={
            location?.pathname == "/app/reports/projects"
              ? ProjectsHead
              : location?.pathname == "/app/reports/inventory"
              ? InventoryHead
              : location?.pathname == "/app/reports/employees"
              ? EmployeesHead
              : location?.pathname == "/app/reports/customers"
              ? CustomersHead
              : location?.pathname == "/app/reports/suppliers"
              ? SuppliersHead
              : location?.pathname == "/app/reports/quotes"
              ? QuotesHead
              : location?.pathname == "/app/reports/sales"
              ? SalesHead
              : location?.pathname == "/app/reports/purchases"
              ? PurchaseHead
              : location?.pathname == "/app/reports/contractors"
              ? ContractorsHead
              : ""
          }
          body={
            location?.pathname == "/app/reports/projects"
              ? ProjectBody
              : location?.pathname == "/app/reports/inventory"
              ? InventoryBody
              : location?.pathname == "/app/reports/employees" && !status
              ? EmployeesBody
              : location?.pathname == "/app/reports/employees" && status
              ? EBody
              : location?.pathname == "/app/reports/customers"
              ? CustomersBody
              : location?.pathname == "/app/reports/suppliers"
              ? SuppliersBody
              : location?.pathname == "/app/reports/quotes"
              ? QuotesBody
              : location?.pathname == "/app/reports/sales"
              ? SalesBody
              : location?.pathname == "/app/reports/purchases"
              ? PurchaseBody
              : location?.pathname == "/app/reports/contractors"
              ? ContractorsBody
              : ""
          }
          foot={
            location?.pathname == "/app/reports/projects"
              ? ProjectFoot
              : location?.pathname == "/app/reports/inventory"
              ? InventoryFoot
              : location?.pathname == "/app/reports/employees" && !status
              ? EmployeesFoot
              : location?.pathname == "/app/reports/employees" && status
              ? EFoot
              : location?.pathname == "/app/reports/customers"
              ? CustomersFoot
              : location?.pathname == "/app/reports/suppliers"
              ? SuppliersFoot
              : location?.pathname == "/app/reports/quotes"
              ? QuotesFoot
              : location?.pathname == "/app/reports/sales"
              ? SalesFoot
              : location?.pathname == "/app/reports/purchases"
              ? PurchaseFoot
              : location?.pathname == "/app/reports/contractors"
              ? ContractorsFoot
              : ""
          }
        />
      </div>
    </div>
  );
};

export default ViewReports;
