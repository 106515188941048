import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const create_employee_role = async (role) => {
  try {
    const data = {
      name: role?.name,
      status: role?.status,
      branch: role?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-employee-role`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const create_employee = async (employee) => {
  try {
    let data = {
      name: employee?.name,
      email: employee?.email,
      phone: employee?.phone,
      role: employee?.role,
      price: employee?.price,
      ot_price: employee?.ot_price,
      area: employee?.area,
      city: employee?.city,
      state: employee?.state,
      country: employee?.country,
      status: employee?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-employee`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_employee = async (employee) => {
  try {
    let data = {
      id: employee?.id,
      name: employee?.name,
      email: employee?.email,
      phone: employee?.phone,
      role: employee?.role,
      price: employee?.price,
      ot_price: employee?.ot_price,
      area: employee?.area,
      city: employee?.city,
      state: employee?.state,
      country: employee?.country,
      status: employee?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-employee`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_employee = async (employee) => {
  try {
    const id = employee?.id;

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${APIURL}/get-employee/${id}`, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_employees = async (employee) => {
  try {
    const data = {
      search: employee?.search,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-employees`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_employee_role,
  create_employee,
  update_employee,
  get_employee,
  get_all_employees,
};
