import { RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddPurchaseForm from "./form";
import headers from "../../../Data/Forms/Purchases/Purchases/headers";
import Header from "../../../Componets/Header";
import { get_create_purchase } from "../../../Controllers/Purchases";
import { toast } from "react-toastify";

const AddPurchase = () => {
  let header = headers;

  return (
    <Header header={header}>
      <AddPurchaseForm />
    </Header>
  );
};

export default AddPurchase;
