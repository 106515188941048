import { DeleteOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ActionButton = (props) => {
  let navigate = useNavigate();
  const edit = props?.edit ? props?.edit : "";
  const remove = props?.delete ? props?.delete : "";

  return (
    <div className="text-xl text-gray-600 ">
      {edit && (
        <button
          className="mx-2 hover:text-[#2d53da]"
          onClick={() => navigate(edit)}
        >
          <FormOutlined />
        </button>
      )}
      {remove && (
        <button className="mx-2 hover:text-red-500">
          <DeleteOutlined />
        </button>
      )}
    </div>
  );
};

export default ActionButton;
