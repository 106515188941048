import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../Componets/Header";
import forms from "../../../Data/Forms/Employees/Employees/forms";
import headers from "../../../Data/Forms/Employees/Employees/headers";
import {
  create_employee,
  get_employee,
  update_employee,
} from "../../../Controllers/Employees";

const EmployeesForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  let form = forms;
  const header = headers;

  const [value, setValue] = useState(form);

  const formdata = async (data) => {
    if (data?.status) {
      const employee = id
        ? await update_employee({ ...data?.data, id: id })
        : await create_employee(data?.data);
      if (employee?.status) {
        toast?.success(employee?.message);
        navigate("/app/employees");
      } else {
        toast?.error(employee?.message);
      }
    }
  };

  const fetchData = async () => {
    if (id) {
      const data = {
        id,
      };
      const employee = await get_employee(data);
      if (employee?.status) {
        let employee_data = employee?.data;

        let name = employee_data?.name ? employee_data?.name : "";
        let role = employee_data?.role ? employee_data?.role : "";
        let email = employee_data?.email ? employee_data?.email : "";
        let phone = employee_data?.phone ? employee_data?.phone : "";
        let price = employee_data?.price ? employee_data?.price : 0;
        let ot_price = employee_data?.ot_price ? employee_data?.ot_price : 0;
        let area = employee_data?.area ? employee_data?.area : "";
        let city = employee_data?.city ? employee_data?.city : "";
        let state = employee_data?.state ? employee_data?.state : "";
        let country = employee_data?.country ? employee_data?.country : "";
        let status = employee_data?.status ? employee_data?.status : 0;

        value[0].options[0].form.value = name;
        value[0].options[1].form.value = role;

        value[1].options[0].form.value = email;
        value[1].options[1].form.value = phone;

        value[2].options[0].form.value = price;
        value[2].options[1].form.value = ot_price;

        value[3].options[0].form.value = area;
        value[3].options[1].form.value = city;
        value[3].options[2].form.value = state;
        value[3].options[3].form.value = country;

        value[4].options[0].form.value = status;

        setValue([...value]);
      } else {
        toast?.error(employee?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Header header={header}>
      <Forms form={value} formdata={formdata} />
    </Header>
  );
};

export default EmployeesForm;
