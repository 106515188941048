import {
  CloudUploadOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const DragDrop = (props) => {
  const [files, setFiles] = useState([]);

  const onfiles = props?.onfiles;

  const onDrop = useCallback(
    (acceptedFiles) => {
      let file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(file);
      if (onfiles) {
        onfiles({ index: props?.index, file: file[0] });
      }
    },
    [props?.index, onfiles]
  );

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [],
        "image/jpg": [],
        "image/png": [],
      },
    });

  const deleteFile = () => {
    setFiles([]);
    onfiles();
  };

  const acceptedFileItems = files.map((file) => (
    <div key={file.name}>
      {file?.type?.includes("image") && (
        <div className="pt-1">
          <img
            src={file.preview}
            className="w-[100px] h-[100px] rounded-md"
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      )}
      <div className="text-xs text-gray-400 text-regular">
        {file.path} - {file.size} bytes
        <button
          type="button"
          className="text-xl text-medium text-red-500 hover:text-red-800 px-2"
          onClick={deleteFile}
        >
          <DeleteOutlined />
        </button>
      </div>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.name}>
      {errors.map((e) => (
        <li key={e.code} className="text-xs text-red-500">
          {e.message}
        </li>
      ))}
    </div>
  ));

  return (
    <section className="">
      {files?.length === 0 && (
        <div className="flex justify-center items-center ">
          <div
            {...getRootProps({
              className:
                "text-center flex flex-col justify-center items-center text-regular text-gray-400 cursor-pointer border hover:border-[#3498db] rounded-lg p-2 h-full w-full",
            })}
          >
            <input {...getInputProps()} />
            <p>
              <CloudUploadOutlined className="" style={{ fontSize: "16px" }} />
            </p>
            <p>Upload</p>
            <p>Drag & Drop</p>
          </div>
        </div>
      )}
      <aside>{acceptedFileItems}</aside>
      <aside>{fileRejectionItems}</aside>
    </section>
  );
};

export default DragDrop;
