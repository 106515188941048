import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../Componets/Header";
import { create_item, get_item, update_item } from "../../../Controllers/Items";
import forms from "../../../Data/Forms/Items/Items/forms";
import headers from "../../../Data/Forms/Items/Items/headers";

const ItemsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      const item = id
        ? await update_item({ ...data?.data, id: id, image: "" })
        : await create_item(data?.data);
      if (item?.status) {
        toast?.success(item?.message);
        navigate("/app/items");
      } else {
        toast?.error(item?.message);
      }
    }
  };

  const handleData = (item) => {
    let image = item?.data?.image ? item?.data?.image : "";
    let name = item?.data?.name ? item?.data?.name : "";
    let units = item?.data?.units ? item?.data?.units : null;
    let category = item?.data?.category ? item?.data?.category : null;
    let brands = item?.data?.brands ? item?.data?.brands : null;
    let price = item?.data?.price ? item?.data?.price : "";
    let tax = item?.data?.tax ? item?.data?.tax : null;
    let is_stock = item?.data?.is_stock ? item?.data?.is_stock : 0;
    let stock = item?.data?.stock ? item?.data?.stock : "";
    let notify = item?.data?.notify ? item?.data?.notify : "";
    let status = item?.data?.status ? item?.data?.status : 0;

    values[0].options[0].form.value = image;
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = units;

    values[1].options[0].form.value = category;
    values[1].options[1].form.value = brands;

    values[2].options[0].form.value = price;
    values[2].options[1].form.value = tax;

    values[3].options[0].form.value = is_stock;
    values[3].options[1].form.value = stock;
    values[3].options[2].form.value = notify;

    values[4].options[0].form.value = status;

    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      const data = {
        id,
      };
      const item = await get_item(data);
      if (item?.status) {
        handleData(item);
      } else {
        toast?.error(item?.message);
      }
    } else {
      handleData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default ItemsForm;
