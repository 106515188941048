import React from "react";
import ProfileButton from "../Buttons/profile";

const Topbar = () => {
  return (
    <div className="bg-white fixed top-0 left-0 w-full z-50 h-16">
      <div className="mx-auto flex items-center h-full">
        <div className="w-64 border-b border-r flex items-center h-full">
          <ProfileButton />
        </div>
        <div className="px-4 flex-1 border-b flex items-center h-full">
          <div className="">
            <div className="text-medium flex">
              {/* <div>
                <img
                  src="https://via.placeholder.com/150"
                  className="w-[35px] h-[35px] rounded-md object-cover"
                />
              </div> */}
              <div className="my-auto">New Construction LLC</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
