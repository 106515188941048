import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const get_create_purchase = async (supplier) => {
  try {
    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${APIURL}/get-create-purchase`, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { get_create_purchase };
