import { Button, Checkbox, Input, Modal, Select } from "antd";
import React, { createRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Error from "../Error";
import Required from "../Required";
import DragDrop from "../Dropzone";
import AddButton from "../Buttons/add";
import PlusButton from "../Buttons/plus";
import Authform from "../AuthForm";
import Popup from "../Modal";
import { select_search } from "../../Controllers/Global";

const Form = (props) => {
  let navigate = useNavigate();
  console.log(props?.form, "props?.form");

  const form = props?.form;
  const setForm = props?.setForm;

  // const [form, setForm] = useState(props?.form);
  const [popup, setPopup] = useState({});
  const formdata = props?.formdata;
  const focus = useRef([]);

  let getFormdata = () => {
    let data = [];
    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data?.push({ [val?.form?.name]: val?.form?.value });
      });
    });

    return data;
  };

  const onchange = (e, index, ind) => {
    let data = [...form];
    data[index].options[ind].form.value = e?.target?.value;
    setForm(data);
  };

  const onselect = (e, index, ind) => {
    let data = [...form];

    data[index].options[ind].form.value = e;

    if (data[index]?.options[ind]?.show?.check) {
      data?.map((value, k) => {
        value?.options?.map((val, i) => {
          if (val?.show?.name == data[index].options[ind].form.name) {
            data[k].options[i].show.status = e ? true : false;
          }
        });
      });
    }

    setForm(data);
  };

  const onsearch = async (e, index, ind) => {
    let data = [...form];

    console.log(e);

    const select = {
      api: data[index].options[ind].form.api,
      search: e,
    };
    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options[ind].form.options = options;
      setForm(data);
    }
  };

  const onfiles = async (file) => {
    console.log(file, "datadata");
    if (file) {
      let data = [...form];

      data[file?.index?.index].options[file?.index?.ind].form.value =
        file?.file;
      setForm(data);
    }
  };

  const onmodal = (e, name, index, ind) => {
    setPopup({
      status: true,
      data: e,
    });
  };

  const oncheckbox = (e, index, ind) => {};

  const validate = (data) => {
    let total = data?.length;
    let count = 0;

    let validated = {};
    data?.map((value, index) => {
      let ind = value?.index + "" + value?.ind;

      if (!value?.value && value?.required) {
        document.getElementById(
          value?.name?.concat(ind)
        ).textContent = `${value?.name} is required`;
        if (index == count) {
          focus.current[value?.name?.concat(ind)].current.focus();
        }
      } else if (value?.value && value?.required) {
        document.getElementById(value?.name?.concat(ind)).textContent = "";
        validated[value?.name] = value?.value;
        count++;
      } else {
        validated[value?.name] = value?.value;
        count++;
      }
    });

    if (total == count) {
      return { status: true, data: validated };
    } else {
      return { status: false, data: validated };
    }
  };

  const handleSave = (e) => {
    e?.preventDefault();

    let data = [];
    form?.map((value, index) => {
      value?.options?.map((val, ind) => {
        data?.push({ index: index, ind: ind, ...val?.form });
      });
    });

    let validated = validate(data);
    formdata(validated);
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    navigate(-1);
  };

  return (
    <form
      className="bg-white p-4 rounded-md p-8 w-[50%] mx-auto"
      onReset={handleCancel}
      onSubmit={handleSave}
    >
      {form?.map((value, index) => {
        return (
          <div>
            <Popup popup={popup} setPopup={setPopup} />
            <div
              className={index > 0 ? "text-medium py-3" : "text-medium pb-3"}
            >
              {value?.name}
            </div>
            <div className="border text-sm text-medium text-gray-500  rounded-md p-4">
              {value?.options?.map((val, ind) => {
                focus.current[val?.form?.name?.concat(index + "" + ind)] =
                  createRef();
                return (
                  <div>
                    {val?.show?.status != false && (
                      <div className="pb-2 text-medium text-gray-500 text-sm">
                        <label htmlFor={index?.toString()?.concat(ind)}>
                          {val?.name} {val?.form?.required && <Required />}
                        </label>
                      </div>
                    )}

                    {/* components */}
                    {val?.show?.status != false && (
                      <div>
                        {val?.type === "input" ? (
                          <div className="pb-4">
                            <Input
                              key={index?.toString()?.concat(ind)}
                              id={index?.toString()?.concat(ind)}
                              ref={
                                focus.current[
                                  val?.form?.name?.concat(index + "" + ind)
                                ]
                              }
                              name={val?.form?.name}
                              type={val?.form?.type}
                              className={
                                val?.form?.class
                                  ? val?.form?.class
                                  : "text-regular"
                              }
                              placeholder={val?.form?.placeholder}
                              onChange={(e) => onchange(e, index, ind)}
                              value={val?.form?.value}
                              autoComplete="off"
                            />
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "select" ? (
                          <div className="pb-4">
                            <Select
                              key={index}
                              name={val?.form?.name}
                              mode={val?.form?.mode}
                              allowClear={val?.form?.allowClear}
                              showSearch={val?.form?.showSearch}
                              filterOption={val?.form?.filterOption}
                              onSearch={(e) => onsearch(e, index, ind)}
                              className={
                                val?.form?.class
                                  ? val?.form?.class
                                  : "text-regular w-full"
                              }
                              placeholder={val?.form?.placeholder}
                              value={val?.form?.value}
                              options={val?.form?.options}
                              onChange={(e) => onselect(e, index, ind)}
                            />
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "add" ? (
                          <div className="pb-4">
                            <div className="flex">
                              <Select
                                key={index}
                                name={val?.form?.name}
                                filterOption={false}
                                showSearch={val?.form?.showSearch}
                                onSearch={(e) => onsearch(e, index, ind)}
                                allowClear={val?.form?.allowClear}
                                className={
                                  val?.form?.class
                                    ? val?.form?.class
                                    : "text-regular w-full"
                                }
                                placeholder={val?.form?.placeholder}
                                value={val?.form?.value}
                                options={val?.form?.options}
                                onChange={(e) => onselect(e, index, ind)}
                              />
                              <div className="pl-3">
                                <PlusButton
                                  onClick={() => navigate(val?.path)}
                                />
                              </div>
                            </div>
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "checkbox" ? (
                          <div className="pb-4 ">
                            <Checkbox
                              key={index}
                              name={val?.form?.placeholder}
                              checked={val?.form?.checked}
                              disabled={val?.form?.disabled}
                              className={
                                val?.form?.class
                                  ? val?.form?.class
                                  : "text-medium text-gray-500 "
                              }
                              onChange={(e) => oncheckbox(e, index, ind)}
                            >
                              <span></span>
                              {val?.form?.placeholder}
                            </Checkbox>
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : val?.type === "upload" ? (
                          <div className="pb-4">
                            <DragDrop
                              onfiles={onfiles}
                              index={{ index: index, ind: ind }}
                            />
                            <Error
                              id={val?.form?.name?.concat(index + "" + ind)}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {/* components */}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="py-6">
        <div className="float-end ">
          <Button className="mr-2 text-medium" htmlType="reset">
            Cancel
          </Button>
          <Button className="text-medium" type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
