import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../Componets/Header";
import forms from "../../../Data/Forms/Customers/Customers/forms";
import headers from "../../../Data/Forms/Customers/Customers/headers";
import {
  create_customer,
  get_customer,
  update_customer,
} from "../../../Controllers/Customers";

const CustomersForms = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  let form = forms;
  const header = headers;

  const [value, setValue] = useState(form);

  const formdata = async (data) => {
    if (data?.status) {
      const customer = id
        ? await update_customer({ ...data?.data, id: id })
        : await create_customer(data?.data);
      if (customer?.status) {
        toast?.success(customer?.message);
        navigate("/app/customers");
      } else {
        toast?.error(customer?.message);
      }
    }
  };

  const fetchData = async () => {
    if (id) {
      const data = {
        id,
      };
      const customer = await get_customer(data);
      if (customer?.status) {
        let customer_data = customer?.data;

        let name = customer_data?.name ? customer_data?.name : "";
        let email = customer_data?.email ? customer_data?.email : "";
        let phone = customer_data?.phone ? customer_data?.phone : "";
        let area = customer_data?.area ? customer_data?.area : "";
        let city = customer_data?.city ? customer_data?.city : "";
        let state = customer_data?.state ? customer_data?.state : "";
        let country = customer_data?.country ? customer_data?.country : "";
        let status = customer_data?.status ? customer_data?.status : 0;

        value[0].options[0].form.value = name;

        value[1].options[0].form.value = email;
        value[1].options[1].form.value = phone;

        value[2].options[0].form.value = area;
        value[2].options[1].form.value = city;
        value[2].options[2].form.value = state;
        value[2].options[3].form.value = country;

        value[3].options[0].form.value = status;

        setValue([...value]);
      } else {
        toast?.error(customer?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Header header={header}>
      <Forms form={value} formdata={formdata} />
    </Header>
  );
};

export default CustomersForms;
