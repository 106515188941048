import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import tasks from "../../../../Data/tasks.json";
import areas from "../../../../Data/areas.json";

const AddTasksForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined className="text-gray-500" />
      <div className="text-medium text-gray-500" style={{ marginTop: 8 }}>
        Upload
      </div>
    </button>
  );

  const [task, setTask] = useState();

  const getArea = (id) => {
    let data;

    areas?.map((value, index) => {
      if (value?.id == id) {
        data = value;
      }
    });

    return data;
  };

  const fetchdata = async () => {
    let area_data = [];
    let task_data;
    if (id) {
      tasks?.map((value, index) => {
        if (value?.id == id) {
          let area = getArea(value?.id);
          task_data = { ...value, area: area };
        }
      });
    }

    areas?.map((value, index) => {
      area_data?.push({ value: value?.name, label: value?.name });
    });

    setTask({ ...task_data, areas: area_data });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <div class="my-8">
      <div class="bg-white border rounded-md p-8 w-[50%] mx-auto">
        {/* detail */}
        <div>
          <div className="text-medium  pb-3">Details</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader mt-1 mb-3 "
                showUploadList={false}
              >
                {task?.image ? (
                  <img
                    src={task?.image}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
            <div>Name</div>
            <div>
              <Input
                className="w-full my-3 text-medium"
                placeholder="Ex : Parking"
                value={task?.name}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="text-medium  pb-3"></div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>Area</div>
            <div>
              <Select
                className="w-full my-3 text-medium"
                placeholder="Ex : Area"
                allowClear={true}
                showSearch={true}
                options={task?.areas}
                value={task?.area?.name}
              />
            </div>
          </div>
        </div>
        {/* stock */}
        {/* price */}
        {/* <div className="pt-6">
          <div className="text-medium  pb-3">Date</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>Start</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="From"
              />
            </div>
            <div>End</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="To"
              />
            </div>
          </div>
        </div> */}
        {/* Status */}
        <div className="pt-6">
          <div className="text-medium  pb-3">Satus</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Radio.Group
              className="w-full"
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="text-medium" value="a">
                Active
              </Radio.Button>
              <Radio.Button className="text-medium" value="b">
                Cancelled
              </Radio.Button>
              <Radio.Button className="text-medium" value="c">
                Inactive
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {/* submit */}
        <div className="py-6">
          <div className="float-end">
            <Button className="mr-2 text-medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="text-medium"
              type="primary"
              onClick={() => navigate(-1)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTasksForm;
