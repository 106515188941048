import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import AddButton from "../../../Componets/Buttons/add";
import { MinusCircleOutlined } from "@ant-design/icons";
import inventories from "../../../Data/inventory.json";

const PurchaseTable = (props) => {
  const form = props?.form;
  const setForm = props?.setForm;

  const addField = () => {
    let fields = {
      item: "",
      units: "",
      price: "",
      quantity: "",
      total: "",
    };

    let allFields = [...form?.details];
    allFields?.push(fields);

    form.details = allFields;
    setForm({ ...form });
  };

  const removeField = (index) => {
    let allFields = [...form?.details];
    allFields?.splice(index, 1);

    form.details = allFields;
    setForm({ ...form });
  };

  const handleItem = (e, index) => {
    form.details[index].item = e;
    setForm({ ...form });
  };

  const handleQuantity = (e, index) => {
    form.details[index][e?.target?.name] = e?.target?.value;

    let price = form.details[index].price ? form.details[index].price : 0;
    let quantity = e?.target?.value;

    if (price > 0) {
      form.details[index].total = price * quantity;
    }
    setForm({ ...form });
  };

  const handlePrice = (e, index) => {
    form.details[index].price = e?.target?.value;

    let price = e?.target?.value;
    let quantity = form.details[index].quantity
      ? form.details[index].quantity
      : 1;

    form.details[index].total = price * quantity;
    setForm({ ...form });
  };

  return (
    <div>
      <table className="w-full">
        <tbody>
          {form?.details?.map((value, index) => {
            return (
              <tr>
                <td className="p-2">
                  <Select
                    className="w-[100%]"
                    showSearch={true}
                    placeholder="Items"
                    value={value?.item ? value?.item : null}
                    options={
                      form?.from == "Supplier"
                        ? form?.supplier_item_options
                        : form?.contractor_item_options
                    }
                    onChange={(e) => handleItem(e, index)}
                  />
                </td>
                <td className="p-2">
                  <Input
                    placeholder="Price"
                    name="price"
                    type="number"
                    value={value?.price}
                    onChange={(e) => handlePrice(e, index)}
                  />
                </td>
                <td className="p-2">
                  <Input
                    placeholder="Quantity"
                    name="quantity"
                    type="number"
                    value={value?.quantity}
                    onChange={(e) => handleQuantity(e, index)}
                  />
                </td>
                <td className="p-2">
                  <Input
                    placeholder="Total"
                    value={value?.total}
                    disabled={true}
                  />
                </td>
                <td className="p-2 text-red-500">
                  {index > 0 && (
                    <MinusCircleOutlined onClick={() => removeField(index)} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="text-right p-4 ">
        <AddButton onClick={addField} />
      </div>
      <div className="text-medium text-black">
        <span>Grand Total</span>
        <span>&nbsp;&nbsp;:&nbsp;&nbsp;</span>
        <span className="">
          {form?.details?.reduce(
            (acc, currentValue) => currentValue && currentValue?.total,
            0
          )}
        </span>
      </div>
    </div>
  );
};

export default PurchaseTable;
