import React from "react";
import Authform from "../../Componets/AuthForm";
import { UserOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router-dom";
import { Login } from "../../Controllers/Auth";
import { toast } from "react-toastify";
import Authorization from "../../Main/authorization";

const Signin = () => {
  let navigate = useNavigate();

  const auth = {
    name: "Sign In",
    button: "Login",
    link: {
      // name: "Signup",
      // path: "/signup",
      // message: "Don't have an account ? ",
    },
    options: [
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Email",
          icon: <UserOutlined className="text-gray-500" />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "password",
        form: {
          type: "text",
          name: "password",
          placeholder: "Password",
          show: false,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  };

  const formdata = async (data) => {
    if (data?.status) {
      const sigin = await Login(data?.data);

      if (sigin?.status) {
        navigate("/app/dashboard");
      } else {
        toast?.error(sigin?.message);
      }
    }
  };

  let isAuthenticate = Authorization();

  return (
    <div className="bg-gray-100">
      {isAuthenticate && <Navigate to="/app/dashboard" />}
      <Authform auth={auth} formdata={formdata} />
    </div>
  );
};

export default Signin;
