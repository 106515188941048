import React from "react";

const EmployeeIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 512.024 512.024"
    >
      <g>
        <path
          d="M357.802 392.31c-28.225 21.688-63.524 34.603-101.789 34.603s-73.564-12.915-101.789-34.603v119.714h203.578V392.31zM392.154 372.405h-4.353v139.619h124.223v-19.749c0-66.096-53.773-119.87-119.87-119.87zM124.223 372.405h-4.353C53.774 372.405 0 426.179 0 492.275v19.749h124.223V372.405zM316.03 31.827v108.567h76.545c-2.629-24.412-11.658-47.44-26.545-67.304-13.119-17.505-30.45-31.708-50-41.263zM145.994 73.092c-14.887 19.865-23.916 42.891-26.544 67.303h76.546V31.827c-19.552 9.555-36.883 23.759-50.002 41.265z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M256.012 396.913c75.756 0 137.388-61.632 137.388-137.388V237.92H118.625v21.605c0 75.756 61.632 137.388 137.387 137.388zM225.995 0h60.035v140.395h-60.035zM84.862 170.395h342.301v37.526H84.862z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default EmployeeIcon;
