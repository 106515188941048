import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TimesheetTable from "./table";
import areas from "../../../../Data/areas.json";
import tasks from "../../../../Data/tasks.json";

const AddTimesheetForm = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined className="text-gray-500" />
      <div className="text-medium text-gray-500" style={{ marginTop: 8 }}>
        Upload
      </div>
    </button>
  );

  const [timesheet, setTimesheet] = useState();

  const fetchData = () => {
    let area_data = [];
    let task_data = [];

    areas?.map((value, index) => {
      area_data?.push({ value: value?.name, label: value?.label });
    });

    tasks?.map((value, index) => {
      task_data?.push({ value: value?.name, label: value?.label });
    });

    setTimesheet({ area: area_data, task: task_data });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div class="my-8">
      <div class="bg-white border rounded-md p-8 w-[80%] mx-auto">
        {/* detail */}
        {location?.pathname == "/app/projects/area/task/timesheet/add" && (
          <div>
            <div className="text-medium  pb-3">Details</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>
                <div>Date</div>
                <div>
                  <DatePicker
                    className="w-full my-3 text-medium"
                    placeholder="Date"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="pt-4">
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            {/* <div>
              <div>Area</div>
              <div>
                <Select
                  className="w-full my-3 text-medium"
                  placeholder="Area"
                  allowClear={true}
                  showSearch={true}
                  options={timesheet?.area}
                />
              </div>
            </div> */}
            <div>
              <div>Task</div>
              <div>
                <Select
                  className="w-full my-3 text-medium"
                  placeholder="Select Task"
                  allowClear={true}
                  showSearch={true}
                  options={timesheet?.task}
                />
              </div>
            </div>
          </div>
        </div>
        {/* stock */}
        {/* table */}
        <div className="pt-6">
          <div className="text-medium  pb-3">Add</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <TimesheetTable />
          </div>
        </div>
        {/* Status */}
        {location?.pathname == "/app/projects/area/task/timesheet/add" && (
          <div className="pt-6">
            <div className="text-medium  pb-3">Satus</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <Radio.Group
                className="w-full"
                defaultValue="a"
                buttonStyle="solid"
              >
                <Radio.Button className="text-medium" value="a">
                  Active
                </Radio.Button>
                <Radio.Button className="text-medium" value="b">
                  Cancelled
                </Radio.Button>
                <Radio.Button className="text-medium" value="c">
                  Inactive
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
        )}
        {/* submit */}
        <div className="py-6">
          <div className="float-end">
            <Button className="mr-2 text-medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="text-medium"
              type="primary"
              onClick={() => navigate(-1)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTimesheetForm;
