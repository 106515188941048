import React from "react";

const DashboardIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 700 700"
    >
      <g>
        <path
          d="M301.93 106.4 102.35 305.85a64 64 0 0 0-18.77 45.29v215.74a46.62 46.62 0 0 0 46.62 46.62h119a46.62 46.62 0 0 0 46.62-46.62v-66.46a15.31 15.31 0 0 1 15.32-15.31h77.8a15.31 15.31 0 0 1 15.32 15.31v66.46a46.62 46.62 0 0 0 46.62 46.62h119a46.62 46.62 0 0 0 46.62-46.62V351.14a64 64 0 0 0-18.77-45.29L398.07 106.4a68 68 0 0 0-96.14 0z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default DashboardIcon;
