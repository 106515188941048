import React from "react";
import Avatar from "react-avatar";

const TableAvatar = (props) => {
  let name = props?.name;

  return (
    <span>
      <Avatar
        name={name}
        size={35}
        textSizeRatio={1.75}
        className="rounded-md"
      />
      &nbsp;&nbsp;{name}
    </span>
  );
};

export default TableAvatar;
