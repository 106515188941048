import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import areas from "../../../../Data/areas.json";
import { toast } from "react-toastify";

const AddAreaForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined className="text-gray-500" />
      <div className="text-medium text-gray-500" style={{ marginTop: 8 }}>
        Upload
      </div>
    </button>
  );

  let [area, setArea] = useState();

  const fetchData = async () => {
    areas?.map((value, index) => {
      if (value?.id == id) {
        setArea({ ...value });
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div class="my-8">
      <div class="bg-white border rounded-md p-8 w-[50%] mx-auto">
        {/* detail */}
        <div>
          <div className="text-medium  pb-3">Details</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader mt-1 mb-3 "
                showUploadList={false}
              >
                {area?.image ? (
                  <img
                    src={area?.image}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
            <div>Name</div>
            <div>
              <Input
                className="w-full my-3 text-medium"
                placeholder="Ex : Basement"
                value={area?.name}
              />
            </div>
          </div>
        </div>
        {/* Status */}
        <div className="pt-6">
          <div className="text-medium  pb-3">Satus</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Radio.Group
              className="w-full"
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="text-medium" value="a">
                Active
              </Radio.Button>
              <Radio.Button className="text-medium" value="b">
                Cancelled
              </Radio.Button>
              <Radio.Button className="text-medium" value="c">
                Inactive
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {/* submit */}
        <div className="py-6">
          <div className="float-end">
            <Button className="mr-2 text-medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="text-medium"
              type="primary"
              onClick={() => {
                navigate(-1);
                toast?.success("Area created");
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAreaForm;
