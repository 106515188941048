const headers = {
  name: "Customers",
  options: {
    menu: [
      {
        name: "Customers",
        path: "/app/customers",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
